<template>
  <h5>{{ this.i18n('scheduling.service_requests.tasks_by_location.plan_installations') }}</h5>
  <p class="text-danger" v-if="this.slotLoaded < Date.now() - 1000 * 60 * 15">
    {{ this.i18n('scheduling.service_requests.tasks_by_location.overtime') }}</p>
  <p>{{ this.i18n('scheduling.service_requests.tasks_by_location.plan_installations_description') }}</p>

  <p>{{ this.i18n('scheduling.service_requests.tasks_by_location.plan_installations_description_second') }}</p>

  <div class="d-flex gap-2 flex-column flex-xl-row align-items-stretch mb-3" ref="suggestion">
    <div v-if="loading" class="text-center w-100">
      <div>Loading...</div>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-if="!loading" v-for="slot in this.suggestions" class="card rounded" :class="this.getSlotClasses(slot)"
      @click="selectSlot(slot)">
      <div class="card-body">
        <div><b>{{ moment(slot['date']).format("LL") }}</b></div>
        <div>{{ localized_time(slot['start']) }} - {{ localized_time(slot['stop']) }}</div>
        <small v-if="slot['total_tasks'] > 1">{{ slot['total_tasks'] }}
          {{ this.i18n('scheduling.service_requests.available') }}</small>

        <div v-if="this.shouldShowGreen(slot)" class="d-flex flex-row justify-content-center align-content-center"><img
            :src="this.images[slot.index]"><span>{{ this.i18n('scheduling.orders.tasks.match.prepend')
            }}<br />{{ slot.saving }}{{ this.i18n('scheduling.orders.tasks.match.append') }}</span></div>
        <div v-else class="d-flex flex-row p-2 justify-content-center align-content-center"></div>
      </div>

    </div>
  </div>
  <div class="p-3" v-if="this.selected_slot">
    <p>{{ this.i18n('scheduling.service_requests.orders_possible') }}:</p>

    <div v-for="entry in this.getSelectedTaskTypes().filter(x => !this.removedFromSlot.includes(x.taskid))">
      <a class="m-2" @click.stop @click="this.removeTaskIDFromSelectedSlot(entry.taskid)" href="javascript:void(0)"><i
          class="bi bi-dash-circle"></i></a>
      <span>
        {{ entry.time }} - {{ entry.name }}
      </span>
    </div>

  </div>
  <p>{{ this.i18n("scheduling.service_requests.tasks_by_location.plan_installations_other_timeslot_explanation") }}</p>

  <button @click="this.$parent.setStep(0);" class="btn btn-secondary">{{ i18n('back') }}</button>
  <button @click="bookSlot(this.selected_slot)" class="btn btn-primary float-end">{{ i18n('next') }}</button>

</template>

<style lang="scss">
@import '../../../assets/stylesheets/default_theme.scss';


img {
  height: 25px;
}

.timeslot-green-select {
  cursor: pointer;

}

.greencard.selected,
.greencard:hover {
  background-color: rgba(10, 80, 1, 0.24) !important;
  -webkit-box-shadow: inset 0 0 0 2px #0d7300;
  -moz-box-shadow: inset 0 0 0 2px #0d7300;
  box-shadow: inset 0 0 0 2px #0d7300;
  //border: 3px solid #0d7300 !important;
}

.orangecard.selected,
.orangecard:hover {
  //background-color: rgb(231, 98, 38) !important;
  color: white !important;
}

.greencard {
  border: 1px solid #0d7300;
  background-color: rgba(10, 80, 1, 0.07);
}

.timeslot-green-select .border {
  border: 1px solid #dee2e6;
}
</style>

<script>
import moment from 'moment/min/moment-with-locales';

import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";

import img1 from "../../../assets/images/eco 1.svg"
import img2 from "../../../assets/images/eco 2.svg"
import img3 from "../../../assets/images/eco 3.svg"
import { patch, post } from "@rails/request.js";

const i18n = new I18n(en);

export default {
  props: {
    concept: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    },
  },

  data() {
    let tasks = this.concept.tasks
    let selected_slot = null

    moment.locale(this.locale);

    if (this.locale === 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }

    return {
      loading: false,
      tasks: tasks,
      selected_slot: selected_slot,
      suggestions: [],
      removedFromSlot: [],
      suggestion_display: [
        { display: this.i18n('scheduling.orders.tasks.match.prepend'), addClass: "bg-primary text-white" },
        { display: this.i18n('scheduling.orders.tasks.match.append'), addClass: "bg-secondary text-white" }
      ],
      images: [img3, img2, img1],
      slotLoaded: Date.now()
    }
  },
  methods: {
    removeTaskIDFromSelectedSlot(id) {
      this.removedFromSlot.push(id);
    },
    getSelectedTaskTypes() {
      let task_type_array = [];

      for (const agent_slot of Object.values(this.selected_slot.agent_slots)) {

        let time = this.moment(agent_slot.start)
        for (const task of agent_slot.tasks) {
          for (const taskConcept of Object.values(this.concept)) {
            for (const taskid of Object.values(taskConcept)) {
              if (taskid.id == task) {

                task_type_array.push({ taskid: task, name: taskid.task_name, time: time.format('LT') })
                time = time.add(taskid.duration_seconds, 'seconds')
                break;
              }
            }

          }
        }

      }
      task_type_array.sort(function (a, b) {
        return b.time.localeCompare(a.time);
      });
      return task_type_array.reverse()
    },
    refreshApp() {
      this.loading = true;
      this.getSuggestions();
    },
    slotSelected(slot) {
      if (!this.selected_slot) return false;
      return this.selected_slot.index == slot.index
    },
    getSlotClasses(slot) {
      let returnclasses = [];
      returnclasses.push(this.shouldShowGreen(slot) ? 'greencard' : 'orangecard border');
      returnclasses.push(this.slotSelected(slot) ? 'selected' : 'timeslot-green-select');
      return returnclasses.join(" ");
    },
    shouldShowGreen(slot) {
      return (slot.index < 3 && slot.saving > 0)
    },
    selectSlot(slot) {
      this.removedFromSlot = [];
      if (this.selected_slot == slot) {
        this.selected_slot = null;
      } else {
        this.selected_slot = slot;
      }
    },
    verifySlot(taskids, date) {
      let data = {
        date: date,
        tasks: taskids
      };
      return post('/availability/suggestions/verify', {
        body: JSON.stringify(data),
        contentType: "application/json",
        respondKind: 'json',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
      })
        .then(response => response.json)
    },
    /* Actions */
    bookSlot(slot) {
      // Check expired
      if (this.slotLoaded < Date.now() - 1000 * 60 * 15) {
        this.refreshApp();
        return;
      }

      let date = new Date(slot.date)
      let taskids = []
      let slotsbuilder = []

      for (const agentid in slot.agent_slots) {
        taskids = taskids.concat(Object.values(slot.agent_slots[agentid].tasks))
        let time = new Date(slot.agent_slots[agentid].start);

        time.setFullYear(date.getFullYear())
        time.setMonth(date.getMonth());
        time.setDate(date.getDate());

        slotsbuilder.push({
          slot: time,
          end: new Date(slot.agent_slots[agentid].stop),
          taskids: slot.agent_slots[agentid].tasks.filter(x => !this.removedFromSlot.includes(x)),
          user_id: parseInt(agentid),
          availability: slot.availability
        })
      }

      this.verifySlot(taskids, date).then((response) => {
        //Cross reference options response and slotbuilder to verify task is still available
        let slotsFitted = 0;
        for (const slotPlanning of slotsbuilder) {
          for (const responseSlot of response) {
            if (responseSlot.agent === slotPlanning.user_id) {
              let responseStart = new Date(responseSlot.start);
              let responseEnd = new Date(responseSlot.stop);
              if (slotPlanning.slot >= responseStart && slotPlanning.end <= responseEnd) {
                slotsFitted++;
              }
            }
          }
        }
        // if (slotsFitted !== slotsbuilder.length) {
        //  alert(this.i18n('scheduling.service_requests.tasks_by_location.overtime'));
        //  this.selected_slot = null;
        // this.refreshApp();
        // return false;
        // }


        let data = {
          slots: {
            slot: slotsbuilder
          }
        };
        patch("tasks/plan_specfic.json", {
          body: JSON.stringify(data),
          contentType: "application/json",
          respondKind: 'json',
          headers: {
            accept: 'application/json'
          }
        }).then(response => response.json)
          .then((data) => {
            // Refresh suggestion
            return data;
          })
          .then((data) => {
            this.$parent.setSlot(taskids.filter(x => !this.removedFromSlot.includes(x)))
            this.$parent.setStep(2);
            return true
          })
          .catch((e) => {
            console.error(e)
            alert("Er is iets fout gegaan, probeer opnieuw!")
          })
      });

    },
    /* Internal methods */
    i18n(key) {
      return i18n.t(key)
    },
    localized_time(date) {
      return moment(date).format('LT')
    },
    moment(date) {
      return moment(date)
    },
    searchDurationByID(id) {
      for (let task of this.tasks) {
        if (task.id === id) return task.duration_seconds / 60;
      }
    },
    getSuggestions() {
      this.slotLoaded = Date.now();
      let simpleTasks = [];
      for (let task of this.tasks) {
        simpleTasks.push(task.id,)
      }
      fetch('/availability/suggestions', { // 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          tasks: simpleTasks
        })
      }).then(response => {
        return response.json()
      })
        .then((data) => {

          data = data.slice(0, 5);
          for (let i = 0; i < data.length; i++) {
            let time = new Date(data[i].start);
            let date = new Date(data[i].date);

            date.setHours(time.getHours())
            date.setMinutes(time.getMinutes())
            data[i].start = date;
          }

          if (this.selected_slot) {
            data.unshift(this.selected_slot)
            data.pop()
          }


          this.suggestions = data;
          this.loading = false;
        })
    }

  },
  mounted() {
    this.loading = true;
    this.getSuggestions();
  }
}
//

const theme = {
  month: {
    dayName: {
      borderLeft: 'none',
      backgroundColor: 'inherit',
    },
    holidayExceptThisMonth: { color: '#f3acac' },
    dayExceptThisMonth: { color: '#bbb' },
    weekend: { backgroundColor: '#fafafa' },
    moreView: { boxShadow: 'none' },
    moreViewTitle: { backgroundColor: '#f4f4f4' },
  },
  week: {
    dayName: {
      borderTop: '0',
      borderBottom: '0',
      borderLeft: '0',
      backgroundColor: 'inherit',
    },
    today: {
      color: '#009688',
      backgroundColor: 'inherit',
    },
    pastTime: { color: '#999' },
    futureTime: { color: '#333' },
    gridSelection: { color: '#135de6' },
  },
}
</script>