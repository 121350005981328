import { Controller } from "@hotwired/stimulus"
import { createApp } from 'vue'
import SchedulerModal from '@/vue_components/scheduler_modal.vue';
import CreateModal from '@/vue_components/scheduler_create_task.vue';
import { createI18n } from 'vue-i18n'
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="tasks"
export default class extends Controller {
  static targets = ["task"]

  initialize() {
  }

  connect() {
    this.usersource = this.element.dataset.usersource
    this.tasktypesource = this.element.dataset.tasktypesource
    this.eventsource = this.element.dataset.eventsource
    this.schedulingBaseUrl = this.element.dataset.schedulingBaseUrl
    this.schedulingServiceRequestBaseUrl = this.element.dataset.schedulingServiceRequestBaseUrl

    console.log("connected");

    this.mountTaskModals()

    fetch(this.usersource)
      .then(response => response.json())
      .then(data => {
        let resources = [];
        for (let i = 0; i < data.length; i++) {
          let resource = {
            id: data[i].id,
            title: data[i].full_name,
            extendedProps: {
              email: data[i].email,
              agent_availabilities: data[i].agent_availabilities,
              user_date_start_end_locations: data[i].user_date_start_end_locations,
              postal_code: data[i].postal_code,
              city: data[i].city,
              house_number: data[i].house_number,
              street: data[i].street,
              country: data[i].country,
            }
          }

          resources.push(resource);
        }

        resources.push({
          id: null,
          title: 'Unassigned'
        })

        this.resources = resources
      })
      .catch(error => {
        console.error(error);
        failureCallback(error);
      });

    this.handleNavigation()
  }

  handleNavigation() {
    // If task_id is in the URL, show the modal
    let url = new URL(window.location.href)
    console.log(url)
    let task_id = url.searchParams.get('task_id')
    if (task_id) {
      let task = document.getElementById('task_' + task_id)
      if (task) {
        console.log(task)
        this.showmodal({ target: task })
      }
    } else {
      this.closemodal()
    }
  }

  disconnect() {
    // Remove all the Vue instances
    for (let i = 0; i < this.taskTargets.length; i++) {
      this.taskTargets[i].__vue_app__.unmount()
    }
  }

  showmodal(e) {
    // Return true if this is a click on a <a> element
    if (e.target.tagName == 'A') {
      return true
    }

    let target = e.target.closest('[data-task]')

    this.modal.resources = this.resources
    this.modal.modalEvent = JSON.parse(target.dataset.task)
  }

  closemodal() {
  }

  mountTaskModals() {
    let self = this
    this.taskTargets.forEach(element => {
      if (!element.__vue_app__) {
        // Add the template to the DOM
        const SchedulerModalTemplate = document.createElement('SchedulerModal')

        console.log(this.schedulingServiceRequestBaseUrl)

        console.log(this.schedulingBaseUrl)
        console.log(this.schedulingServiceRequestBaseUrl)

        SchedulerModalTemplate.setAttribute('scheduling-base-url', this.schedulingBaseUrl)
        SchedulerModalTemplate.setAttribute('scheduling-service-request-base-url', this.schedulingServiceRequestBaseUrl)
        SchedulerModalTemplate.setAttribute(':event', 'modalEvent')
        SchedulerModalTemplate.setAttribute(':users', 'resources')
        SchedulerModalTemplate.setAttribute('ref', 'modal')

        SchedulerModalTemplate.setAttribute('v-on:destroy', 'deleteEvent')
        SchedulerModalTemplate.setAttribute('v-on:close', 'closeEventModal')
        SchedulerModalTemplate.setAttribute('v-on:save', 'saveTask')
        SchedulerModalTemplate.setAttribute('v-on:confirm', 'confirmEvent')

        element.appendChild(SchedulerModalTemplate)

        let modal = createApp({
          components: {
            schedulermodal: SchedulerModal
          },
          data() {
            return {
              modalEvent: {},
              resources: [],
            }
          },
          watch: {
          },
          compilerOptions: {
            isCustomElement: tag => {
              tag.startsWith('turbo')
            }
          },
          methods: {
            saveTask: self.saveTask.bind(self),
            deleteEvent: self.deleteEvent.bind(self),
            closeEventModal: () => {
              this.modalEvent = null
              this.closemodal()
            },
            confirmEvent: (event) => {
              event.confirmed = true
              this.saveTask(event)
            },
            setEvent: (event) => {
              this.modalEvent = event
            }
          },
          mounted: function () {
            if (self.resourcesurlValue) {
              fetch(self.resourcesurlValue)
                .then(response => response.json())
                .then(data => {
                  this.resources = data
                })
            }
          }
        }).mount(element)

        this.modal = modal
        window.eventModal = modal
      }
    });
  }

  saveTask(event) {
    if (this.eventsource) {
      return fetch(this.eventsource.replace('.json', '') + '/' + event.event.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(event)
      }).then((response) => {
        return response.json()
      }).then((data) => {
        document.querySelectorAll('[data-task]').forEach(element => {
          if (element.dataset.task) {
            JSON.parse(element.dataset.task).id == event.event.id ? element.dataset.task = JSON.stringify(data) : null
          }
        });
      });
    }
  }

  deleteEvent(event) {
    fetch(this.eventsource.replace('.json', '') + '/' + event.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(event)
    }).then((response) => {
      return response.json()
    }).then((data) => {
      document.querySelectorAll('[data-task]').forEach(element => {
        if (element.dataset.task) {
          JSON.parse(element.dataset.task).id == data.id ? element.remove() : null
          this.modal.modalEvent = null
        }
      });
    });
  }

}
