import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="licenseplate-lookup"
export default class extends Controller {
  static  targets = ["licenseplate","brand","model"]
  url = '';

  connect() {
    if(document.querySelector("meta[name=rdw]") !== null){
      this.url = document.querySelector("meta[name=rdw]").content;
    } else {
      console.error('No meta tag with name "rdw" found');
    }
  }

  dataReceived(data) {
    if(data.length > 0){
      this.brandTarget.value = data[0].merk;
      this.brandTarget.dispatchEvent(new Event('input'));
      this.modelTarget.value = data[0].handelsbenaming;
      this.modelTarget.dispatchEvent(new Event('input'));
    }
  }
  //# TODO: create api key so we dont run into limit issues.
  // https://dev.socrata.com/foundry/opendata.rdw.nl/m9d7-ebf2

  input(ev){
    let plateTarget = this.licenseplateTarget;
    let plate = plateTarget.value.toUpperCase();
    plateTarget.value = plate;

    plate = plate.replaceAll('-', '')

    if(plate.length === 6 && ev.keyCode != 8){
      plateTarget.blur();
      fetch(this.url+'?kenteken='+plate)
          .then((res) => res.json())
          .then((data) => this.dataReceived(data))
    }
  }
}
