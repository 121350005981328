import { Controller } from "@hotwired/stimulus"

import CountryCodeConverter from "../country_code_alpha3_to_2"

// Connects to data-controller="address-lookup"
export default class extends Controller {
  static targets = ["postalcode", "housenumber", "city", "street", "countrycode", "country"];
  url = '';

  connect() {
    this.host = window.location.protocol + "//" + window.location.host;

    console.log('Address Lookup Controller Connected');

    // Check target elements
    if (!this.postalcodeTarget) {
      console.error('No postalcode target found');
    }

    if (!this.housenumberTarget) {
      console.error('No housenumber target found');
    }

    if (!this.cityTarget) {
      console.error('No city target found');
    }

    if (!this.streetTarget) {
      console.error('No street target found');
    }

    if (!this.countryTarget) {
      console.error('No country target found');
    } else {
      console.log('Country target found');
    }
  }

  dataReceived(data) {
    if (this.cityTarget && data.city) {
      this.cityTarget.value = data.city;
      this.cityTarget.dispatchEvent(new Event('input'));
    }

    if (this.streetTarget && data.street) {
      this.streetTarget.value = data.street;
      this.streetTarget.dispatchEvent(new Event('input'));
    }

    console.log('data', data);

    if (this.countryTarget && data.countryCode) {
      console.log('setting country');
      console.log(data.countrycode);

      // Convert 3166-Alpha-3 to 3166-Alpha-2
      let aplha3 = data.countryCode;
      let alpha2 = CountryCodeConverter.convertCountryCodeAlpha3To2(aplha3);

      this.countryTarget.value = alpha2;
      this.countryTarget.dispatchEvent(new Event('input'));
    }
  }

  lookup(e) {
    if (!this.postalcodeTarget || !this.postalcodeTarget.value || !this.housenumberTarget || !this.housenumberTarget.value) {
      return
    }
    let postal_code = this.postalcodeTarget.value.toUpperCase();
    let house_number = this.housenumberTarget.value;

    this.postalcodeTarget.value = postal_code;
    postal_code = postal_code.replaceAll(' ', '')

    if (postal_code.length === 6 && house_number.length > 0) {
      fetch(this.host + "/api/v1/location/lookup_address?postal_code=" + postal_code + "&house_number=" + house_number)
        .then((res) => res.json())
        .then((data) => this.dataReceived(data))
    }
  }
}
