<template>
  <div v-if="!this.htmlbody" class="text-center w-100 mb-3">
    <div>Loading...</div>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="d-flex w-100 mb-1" v-html="htmlbody"  ref="suggestion">
  </div>
  <button class="btn btn-secondary w-100" @click="this.$parent.setStep(1)">{{i18n("back")}}</button>
</template>

<style lang="scss">
  @import '../../../assets/stylesheets/default_theme.scss';

</style>

<script>
import moment from 'moment/min/moment-with-locales';

import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";

import img1 from "../../../assets/images/eco 1.svg"
import img2 from "../../../assets/images/eco 2.svg"
import img3 from "../../../assets/images/eco 3.svg"
import {patch} from "@rails/request.js";
import { nextTick } from 'vue'

const i18n = new I18n(en);

export default {
  props: {
    concept: {
      type: Object,
      required: true
    },
    slots: {
      type: Array,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    },
  },
  data() {
    let tasks = this.slots
    moment.locale(this.locale);

    if(this.locale === 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }

    return {
      tasks: tasks,
      htmlbody: null,
      id:[],
      images: [img3,img2,img1]
    }
  },
  methods: {

    i18n (key) {
      return i18n.t(key)
    },
    submit_task_adres_from(e){
      //Making call async so forms submits regardless
      const int = setInterval(()=>{
        if(!e.target.disabled){
          clearInterval(int);

          fetch('finalize', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
          }).then(response => {
            return  response.json()})
              .then((data) => {
                if('redirect_url' in data && data['redirect_url'] && data['redirect_url'].length > 5){
                  window.open(data['redirect_url'], '_blank');
                }
                this.$parent.$parent.fetch();
              })
        }
      },300)
    },
  },
  mounted () {
    for (const agentSlot of Object.values(this.slots)) {
      this.id.push(agentSlot)
    }
    fetch('task_field?id='+JSON.stringify(this.id), {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      return  response.text()})
        .then((data) => {
            this.htmlbody = data
            nextTick(() => document.getElementById("submit_"+JSON.stringify(this.id)).onclick =  this.submit_task_adres_from)

          // setTimeout(
          //
          //     ,150)
        })
  }
}
//

const theme = {

}
</script>