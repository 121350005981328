import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="service-code"
export default class extends Controller {
  static targets = [ "uuid"]

  connect() {
  }

  redirect() {
    window.location = "/schedule/orders/" + this.uuidTarget.value;
  }
}
