export default class CountryCodeConverter {
  static countryCodes = [
    { "alpha2": "AF", "alpha3": "AFG", "numeric": "004", "name": "Afghanistan" },
    { "alpha2": "AX", "alpha3": "ALA", "numeric": "248", "name": "Åland Islands" },
    { "alpha2": "AL", "alpha3": "ALB", "numeric": "008", "name": "Albania" },
    { "alpha2": "DZ", "alpha3": "DZA", "numeric": "012", "name": "Algeria" },
    { "alpha2": "AS", "alpha3": "ASM", "numeric": "016", "name": "American Samoa" },
    { "alpha2": "AD", "alpha3": "AND", "numeric": "020", "name": "Andorra" },
    { "alpha2": "AO", "alpha3": "AGO", "numeric": "024", "name": "Angola" },
    { "alpha2": "AI", "alpha3": "AIA", "numeric": "660", "name": "Anguilla" },
    { "alpha2": "AQ", "alpha3": "ATA", "numeric": "010", "name": "Antarctica" },
    { "alpha2": "AG", "alpha3": "ATG", "numeric": "028", "name": "Antigua and Barbuda" },
    { "alpha2": "AR", "alpha3": "ARG", "numeric": "032", "name": "Argentina" },
    { "alpha2": "AM", "alpha3": "ARM", "numeric": "051", "name": "Armenia" },
    { "alpha2": "AW", "alpha3": "ABW", "numeric": "533", "name": "Aruba" },
    { "alpha2": "AU", "alpha3": "AUS", "numeric": "036", "name": "Australia" },
    { "alpha2": "AT", "alpha3": "AUT", "numeric": "040", "name": "Austria" },
    { "alpha2": "AZ", "alpha3": "AZE", "numeric": "031", "name": "Azerbaijan" },
    { "alpha2": "BS", "alpha3": "BHS", "numeric": "044", "name": "Bahamas" },
    { "alpha2": "BH", "alpha3": "BHR", "numeric": "048", "name": "Bahrain" },
    { "alpha2": "BD", "alpha3": "BGD", "numeric": "050", "name": "Bangladesh" },
    { "alpha2": "BB", "alpha3": "BRB", "numeric": "052", "name": "Barbados" },
    { "alpha2": "BY", "alpha3": "BLR", "numeric": "112", "name": "Belarus" },
    { "alpha2": "BE", "alpha3": "BEL", "numeric": "056", "name": "Belgium" },
    { "alpha2": "BZ", "alpha3": "BLZ", "numeric": "084", "name": "Belize" },
    { "alpha2": "BJ", "alpha3": "BEN", "numeric": "204", "name": "Benin" },
    { "alpha2": "BM", "alpha3": "BMU", "numeric": "060", "name": "Bermuda" },
    { "alpha2": "BT", "alpha3": "BTN", "numeric": "064", "name": "Bhutan" },
    { "alpha2": "BO", "alpha3": "BOL", "numeric": "068", "name": "Bolivia (Plurinational State of)" },
    { "alpha2": "BQ", "alpha3": "BES", "numeric": "535", "name": "Bonaire, Sint Eustatius and Saba" },
    { "alpha2": "BA", "alpha3": "BIH", "numeric": "070", "name": "Bosnia and Herzegovina" },
    { "alpha2": "BW", "alpha3": "BWA", "numeric": "072", "name": "Botswana" },
    { "alpha2": "BV", "alpha3": "BVT", "numeric": "074", "name": "Bouvet Island" },
    { "alpha2": "BR", "alpha3": "BRA", "numeric": "076", "name": "Brazil" },
    { "alpha2": "IO", "alpha3": "IOT", "numeric": "086", "name": "British Indian Ocean Territory" },
    { "alpha2": "BN", "alpha3": "BRN", "numeric": "096", "name": "Brunei Darussalam" },
    { "alpha2": "BG", "alpha3": "BGR", "numeric": "100", "name": "Bulgaria" },
    { "alpha2": "BF", "alpha3": "BFA", "numeric": "854", "name": "Burkina Faso" },
    { "alpha2": "BI", "alpha3": "BDI", "numeric": "108", "name": "Burundi" },
    { "alpha2": "CV", "alpha3": "CPV", "numeric": "132", "name": "Cabo Verde" },
    { "alpha2": "KH", "alpha3": "KHM", "numeric": "116", "name": "Cambodia" },
    { "alpha2": "CM", "alpha3": "CMR", "numeric": "120", "name": "Cameroon" },
    { "alpha2": "CA", "alpha3": "CAN", "numeric": "124", "name": "Canada" },
    { "alpha2": "KY", "alpha3": "CYM", "numeric": "136", "name": "Cayman Islands" },
    { "alpha2": "CF", "alpha3": "CAF", "numeric": "140", "name": "Central African Republic" },
    { "alpha2": "TD", "alpha3": "TCD", "numeric": "148", "name": "Chad" },
    { "alpha2": "CL", "alpha3": "CHL", "numeric": "152", "name": "Chile" },
    { "alpha2": "CN", "alpha3": "CHN", "numeric": "156", "name": "China" },
    { "alpha2": "CX", "alpha3": "CXR", "numeric": "162", "name": "Christmas Island" },
    { "alpha2": "CC", "alpha3": "CCK", "numeric": "166", "name": "Cocos (Keeling) Islands" },
    { "alpha2": "CO", "alpha3": "COL", "numeric": "170", "name": "Colombia" },
    { "alpha2": "KM", "alpha3": "COM", "numeric": "174", "name": "Comoros" },
    { "alpha2": "CG", "alpha3": "COG", "numeric": "178", "name": "Congo" },
    { "alpha2": "CD", "alpha3": "COD", "numeric": "180", "name": "Congo, Democratic Republic of the" },
    { "alpha2": "CK", "alpha3": "COK", "numeric": "184", "name": "Cook Islands" },
    { "alpha2": "CR", "alpha3": "CRI", "numeric": "188", "name": "Costa Rica" },
    { "alpha2": "CI", "alpha3": "CIV", "numeric": "384", "name": "Côte d'Ivoire" },
    { "alpha2": "HR", "alpha3": "HRV", "numeric": "191", "name": "Croatia" },
    { "alpha2": "CU", "alpha3": "CUB", "numeric": "192", "name": "Cuba" },
    { "alpha2": "CW", "alpha3": "CUW", "numeric": "531", "name": "Curaçao" },
    { "alpha2": "CY", "alpha3": "CYP", "numeric": "196", "name": "Cyprus" },
    { "alpha2": "CZ", "alpha3": "CZE", "numeric": "203", "name": "Czechia" },
    { "alpha2": "DK", "alpha3": "DNK", "numeric": "208", "name": "Denmark" },
    { "alpha2": "DJ", "alpha3": "DJI", "numeric": "262", "name": "Djibouti" },
    { "alpha2": "DM", "alpha3": "DMA", "numeric": "212", "name": "Dominica" },
    { "alpha2": "DO", "alpha3": "DOM", "numeric": "214", "name": "Dominican Republic" },
    { "alpha2": "EC", "alpha3": "ECU", "numeric": "218", "name": "Ecuador" },
    { "alpha2": "EG", "alpha3": "EGY", "numeric": "818", "name": "Egypt" },
    { "alpha2": "SV", "alpha3": "SLV", "numeric": "222", "name": "El Salvador" },
    { "alpha2": "GQ", "alpha3": "GNQ", "numeric": "226", "name": "Equatorial Guinea" },
    { "alpha2": "ER", "alpha3": "ERI", "numeric": "232", "name": "Eritrea" },
    { "alpha2": "EE", "alpha3": "EST", "numeric": "233", "name": "Estonia" },
    { "alpha2": "ET", "alpha3": "ETH", "numeric": "231", "name": "Ethiopia" },
    { "alpha2": "FK", "alpha3": "FLK", "numeric": "238", "name": "Falkland Islands (Malvinas)" },
    { "alpha2": "FO", "alpha3": "FRO", "numeric": "234", "name": "Faroe Islands" },
    { "alpha2": "FJ", "alpha3": "FJI", "numeric": "242", "name": "Fiji" },
    { "alpha2": "FI", "alpha3": "FIN", "numeric": "246", "name": "Finland" },
    { "alpha2": "FR", "alpha3": "FRA", "numeric": "250", "name": "France" },
    { "alpha2": "GF", "alpha3": "GUF", "numeric": "254", "name": "French Guiana" },
    { "alpha2": "PF", "alpha3": "PYF", "numeric": "258", "name": "French Polynesia" },
    { "alpha2": "TF", "alpha3": "ATF", "numeric": "260", "name": "French Southern Territories" },
    { "alpha2": "GA", "alpha3": "GAB", "numeric": "266", "name": "Gabon" },
    { "alpha2": "GM", "alpha3": "GMB", "numeric": "270", "name": "Gambia" },
    { "alpha2": "GE", "alpha3": "GEO", "numeric": "268", "name": "Georgia" },
    { "alpha2": "DE", "alpha3": "DEU", "numeric": "276", "name": "Germany" },
    { "alpha2": "GH", "alpha3": "GHA", "numeric": "288", "name": "Ghana" },
    { "alpha2": "GI", "alpha3": "GIB", "numeric": "292", "name": "Gibraltar" },
    { "alpha2": "GR", "alpha3": "GRC", "numeric": "300", "name": "Greece" },
    { "alpha2": "GL", "alpha3": "GRL", "numeric": "304", "name": "Greenland" },
    { "alpha2": "GD", "alpha3": "GRD", "numeric": "308", "name": "Grenada" },
    { "alpha2": "GP", "alpha3": "GLP", "numeric": "312", "name": "Guadeloupe" },
    { "alpha2": "GU", "alpha3": "GUM", "numeric": "316", "name": "Guam" },
    { "alpha2": "GT", "alpha3": "GTM", "numeric": "320", "name": "Guatemala" },
    { "alpha2": "GG", "alpha3": "GGY", "numeric": "831", "name": "Guernsey" },
    { "alpha2": "GN", "alpha3": "GIN", "numeric": "324", "name": "Guinea" },
    { "alpha2": "GW", "alpha3": "GNB", "numeric": "624", "name": "Guinea-Bissau" },
    { "alpha2": "GY", "alpha3": "GUY", "numeric": "328", "name": "Guyana" },
    { "alpha2": "HT", "alpha3": "HTI", "numeric": "332", "name": "Haiti" },
    { "alpha2": "HM", "alpha3": "HMD", "numeric": "334", "name": "Heard Island and McDonald Islands" },
    { "alpha2": "VA", "alpha3": "VAT", "numeric": "336", "name": "Holy See" },
    { "alpha2": "HN", "alpha3": "HND", "numeric": "340", "name": "Honduras" },
    { "alpha2": "HK", "alpha3": "HKG", "numeric": "344", "name": "Hong Kong" },
    { "alpha2": "HU", "alpha3": "HUN", "numeric": "348", "name": "Hungary" },
    { "alpha2": "IS", "alpha3": "ISL", "numeric": "352", "name": "Iceland" },
    { "alpha2": "IN", "alpha3": "IND", "numeric": "356", "name": "India" },
    { "alpha2": "ID", "alpha3": "IDN", "numeric": "360", "name": "Indonesia" },
    { "alpha2": "IR", "alpha3": "IRN", "numeric": "364", "name": "Iran (Islamic Republic of)" },
    { "alpha2": "IQ", "alpha3": "IRQ", "numeric": "368", "name": "Iraq" },
    { "alpha2": "IE", "alpha3": "IRL", "numeric": "372", "name": "Ireland" },
    { "alpha2": "IM", "alpha3": "IMN", "numeric": "833", "name": "Isle of Man" },
    { "alpha2": "IL", "alpha3": "ISR", "numeric": "376", "name": "Israel" },
    { "alpha2": "IT", "alpha3": "ITA", "numeric": "380", "name": "Italy" },
    { "alpha2": "JM", "alpha3": "JAM", "numeric": "388", "name": "Jamaica" },
    { "alpha2": "JP", "alpha3": "JPN", "numeric": "392", "name": "Japan" },
    { "alpha2": "JE", "alpha3": "JEY", "numeric": "832", "name": "Jersey" },
    { "alpha2": "JO", "alpha3": "JOR", "numeric": "400", "name": "Jordan" },
    { "alpha2": "KZ", "alpha3": "KAZ", "numeric": "398", "name": "Kazakhstan" },
    { "alpha2": "KE", "alpha3": "KEN", "numeric": "404", "name": "Kenya" },
    { "alpha2": "KI", "alpha3": "KIR", "numeric": "296", "name": "Kiribati" },
    { "alpha2": "KP", "alpha3": "PRK", "numeric": "408", "name": "Korea (Democratic People's Republic of)" },
    { "alpha2": "KR", "alpha3": "KOR", "numeric": "410", "name": "Korea, Republic of" },
    { "alpha2": "KW", "alpha3": "KWT", "numeric": "414", "name": "Kuwait" },
    { "alpha2": "KG", "alpha3": "KGZ", "numeric": "417", "name": "Kyrgyzstan" },
    { "alpha2": "LA", "alpha3": "LAO", "numeric": "418", "name": "Lao People's Democratic Republic" },
    { "alpha2": "LV", "alpha3": "LVA", "numeric": "428", "name": "Latvia" },
    { "alpha2": "LB", "alpha3": "LBN", "numeric": "422", "name": "Lebanon" },
    { "alpha2": "LS", "alpha3": "LSO", "numeric": "426", "name": "Lesotho" },
    { "alpha2": "LR", "alpha3": "LBR", "numeric": "430", "name": "Liberia" },
    { "alpha2": "LY", "alpha3": "LBY", "numeric": "434", "name": "Libya" },
    { "alpha2": "LI", "alpha3": "LIE", "numeric": "438", "name": "Liechtenstein" },
    { "alpha2": "LT", "alpha3": "LTU", "numeric": "440", "name": "Lithuania" },
    { "alpha2": "LU", "alpha3": "LUX", "numeric": "442", "name": "Luxembourg" },
    { "alpha2": "MO", "alpha3": "MAC", "numeric": "446", "name": "Macao" },
    { "alpha2": "MG", "alpha3": "MDG", "numeric": "450", "name": "Madagascar" },
    { "alpha2": "MW", "alpha3": "MWI", "numeric": "454", "name": "Malawi" },
    { "alpha2": "MY", "alpha3": "MYS", "numeric": "458", "name": "Malaysia" },
    { "alpha2": "MV", "alpha3": "MDV", "numeric": "462", "name": "Maldives" },
    { "alpha2": "ML", "alpha3": "MLI", "numeric": "466", "name": "Mali" },
    { "alpha2": "MT", "alpha3": "MLT", "numeric": "470", "name": "Malta" },
    { "alpha2": "MH", "alpha3": "MHL", "numeric": "584", "name": "Marshall Islands" },
    { "alpha2": "MQ", "alpha3": "MTQ", "numeric": "474", "name": "Martinique" },
    { "alpha2": "MR", "alpha3": "MRT", "numeric": "478", "name": "Mauritania" },
    { "alpha2": "MU", "alpha3": "MUS", "numeric": "480", "name": "Mauritius" },
    { "alpha2": "YT", "alpha3": "MYT", "numeric": "175", "name": "Mayotte" },
    { "alpha2": "MX", "alpha3": "MEX", "numeric": "484", "name": "Mexico" },
    { "alpha2": "FM", "alpha3": "FSM", "numeric": "583", "name": "Micronesia (Federated States of)" },
    { "alpha2": "MD", "alpha3": "MDA", "numeric": "498", "name": "Moldova (Republic of)" },
    { "alpha2": "MC", "alpha3": "MCO", "numeric": "492", "name": "Monaco" },
    { "alpha2": "MN", "alpha3": "MNG", "numeric": "496", "name": "Mongolia" },
    { "alpha2": "ME", "alpha3": "MNE", "numeric": "499", "name": "Montenegro" },
    { "alpha2": "MS", "alpha3": "MSR", "numeric": "500", "name": "Montserrat" },
    { "alpha2": "MA", "alpha3": "MAR", "numeric": "504", "name": "Morocco" },
    { "alpha2": "MZ", "alpha3": "MOZ", "numeric": "508", "name": "Mozambique" },
    { "alpha2": "MM", "alpha3": "MMR", "numeric": "104", "name": "Myanmar" },
    { "alpha2": "NA", "alpha3": "NAM", "numeric": "516", "name": "Namibia" },
    { "alpha2": "NR", "alpha3": "NRU", "numeric": "520", "name": "Nauru" },
    { "alpha2": "NP", "alpha3": "NPL", "numeric": "524", "name": "Nepal" },
    { "alpha2": "NL", "alpha3": "NLD", "numeric": "528", "name": "Netherlands" },
    { "alpha2": "NC", "alpha3": "NCL", "numeric": "540", "name": "New Caledonia" },
    { "alpha2": "NZ", "alpha3": "NZL", "numeric": "554", "name": "New Zealand" },
    { "alpha2": "NI", "alpha3": "NIC", "numeric": "558", "name": "Nicaragua" },
    { "alpha2": "NE", "alpha3": "NER", "numeric": "562", "name": "Niger" },
    { "alpha2": "NG", "alpha3": "NGA", "numeric": "566", "name": "Nigeria" },
    { "alpha2": "NU", "alpha3": "NIU", "numeric": "570", "name": "Niue" },
    { "alpha2": "NF", "alpha3": "NFK", "numeric": "574", "name": "Norfolk Island" },
    { "alpha2": "MK", "alpha3": "MKD", "numeric": "807", "name": "North Macedonia" },
    { "alpha2": "MP", "alpha3": "MNP", "numeric": "580", "name": "Northern Mariana Islands" },
    { "alpha2": "NO", "alpha3": "NOR", "numeric": "578", "name": "Norway" },
    { "alpha2": "OM", "alpha3": "OMN", "numeric": "512", "name": "Oman" },
    { "alpha2": "PK", "alpha3": "PAK", "numeric": "586", "name": "Pakistan" },
    { "alpha2": "PW", "alpha3": "PLW", "numeric": "585", "name": "Palau" },
    { "alpha2": "PS", "alpha3": "PSE", "numeric": "275", "name": "Palestine, State of" },
    { "alpha2": "PA", "alpha3": "PAN", "numeric": "591", "name": "Panama" },
    { "alpha2": "PG", "alpha3": "PNG", "numeric": "598", "name": "Papua New Guinea" },
    { "alpha2": "PY", "alpha3": "PRY", "numeric": "600", "name": "Paraguay" },
    { "alpha2": "PE", "alpha3": "PER", "numeric": "604", "name": "Peru" },
    { "alpha2": "PH", "alpha3": "PHL", "numeric": "608", "name": "Philippines" },
    { "alpha2": "PN", "alpha3": "PCN", "numeric": "612", "name": "Pitcairn" },
    { "alpha2": "PL", "alpha3": "POL", "numeric": "616", "name": "Poland" },
    { "alpha2": "PT", "alpha3": "PRT", "numeric": "620", "name": "Portugal" },
    { "alpha2": "PR", "alpha3": "PRI", "numeric": "630", "name": "Puerto Rico" },
    { "alpha2": "QA", "alpha3": "QAT", "numeric": "634", "name": "Qatar" },
    { "alpha2": "RO", "alpha3": "ROU", "numeric": "642", "name": "Romania" },
    { "alpha2": "RU", "alpha3": "RUS", "numeric": "643", "name": "Russian Federation" },
    { "alpha2": "RW", "alpha3": "RWA", "numeric": "646", "name": "Rwanda" },
    { "alpha2": "RE", "alpha3": "REU", "numeric": "638", "name": "Réunion" },
    { "alpha2": "BL", "alpha3": "BLM", "numeric": "652", "name": "Saint Barthélemy" },
    { "alpha2": "SH", "alpha3": "SHN", "numeric": "654", "name": "Saint Helena, Ascension and Tristan da Cunha" },
    { "alpha2": "KN", "alpha3": "KNA", "numeric": "659", "name": "Saint Kitts and Nevis" },
    { "alpha2": "LC", "alpha3": "LCA", "numeric": "662", "name": "Saint Lucia" },
    { "alpha2": "MF", "alpha3": "MAF", "numeric": "663", "name": "Saint Martin (French part)" },
    { "alpha2": "PM", "alpha3": "SPM", "numeric": "666", "name": "Saint Pierre and Miquelon" },
    { "alpha2": "VC", "alpha3": "VCT", "numeric": "670", "name": "Saint Vincent and the Grenadines" },
    { "alpha2": "WS", "alpha3": "WSM", "numeric": "882", "name": "Samoa" },
    { "alpha2": "SM", "alpha3": "SMR", "numeric": "674", "name": "San Marino" },
    { "alpha2": "ST", "alpha3": "STP", "numeric": "678", "name": "Sao Tome and Principe" },
    { "alpha2": "SA", "alpha3": "SAU", "numeric": "682", "name": "Saudi Arabia" },
    { "alpha2": "SN", "alpha3": "SEN", "numeric": "686", "name": "Senegal" },
    { "alpha2": "RS", "alpha3": "SRB", "numeric": "688", "name": "Serbia" },
    { "alpha2": "SC", "alpha3": "SYC", "numeric": "690", "name": "Seychelles" },
    { "alpha2": "SL", "alpha3": "SLE", "numeric": "694", "name": "Sierra Leone" },
    { "alpha2": "SG", "alpha3": "SGP", "numeric": "702", "name": "Singapore" },
    { "alpha2": "SX", "alpha3": "SXM", "numeric": "534", "name": "Sint Maarten (Dutch part)" },
    { "alpha2": "SK", "alpha3": "SVK", "numeric": "703", "name": "Slovakia" },
    { "alpha2": "SI", "alpha3": "SVN", "numeric": "705", "name": "Slovenia" },
    { "alpha2": "SB", "alpha3": "SLB", "numeric": "090", "name": "Solomon Islands" },
    { "alpha2": "SO", "alpha3": "SOM", "numeric": "706", "name": "Somalia" },
    { "alpha2": "ZA", "alpha3": "ZAF", "numeric": "710", "name": "South Africa" },
    { "alpha2": "GS", "alpha3": "SGS", "numeric": "239", "name": "South Georgia and the South Sandwich Islands" },
    { "alpha2": "SS", "alpha3": "SSD", "numeric": "728", "name": "South Sudan" },
    { "alpha2": "ES", "alpha3": "ESP", "numeric": "724", "name": "Spain" },
    { "alpha2": "LK", "alpha3": "LKA", "numeric": "144", "name": "Sri Lanka" },
    { "alpha2": "SD", "alpha3": "SDN", "numeric": "729", "name": "Sudan" },
    { "alpha2": "SR", "alpha3": "SUR", "numeric": "740", "name": "Suriname" },
    { "alpha2": "SJ", "alpha3": "SJM", "numeric": "744", "name": "Svalbard and Jan Mayen" },
    { "alpha2": "SE", "alpha3": "SWE", "numeric": "752", "name": "Sweden" },
    { "alpha2": "CH", "alpha3": "CHE", "numeric": "756", "name": "Switzerland" },
    { "alpha2": "SY", "alpha3": "SYR", "numeric": "760", "name": "Syrian Arab Republic" },
    { "alpha2": "TW", "alpha3": "TWN", "numeric": "158", "name": "Taiwan (Province of China)" },
    { "alpha2": "TJ", "alpha3": "TJK", "numeric": "762", "name": "Tajikistan" },
    { "alpha2": "TZ", "alpha3": "TZA", "numeric": "834", "name": "Tanzania, United Republic of" },
    { "alpha2": "TH", "alpha3": "THA", "numeric": "764", "name": "Thailand" },
    { "alpha2": "TL", "alpha3": "TLS", "numeric": "626", "name": "Timor-Leste" },
    { "alpha2": "TG", "alpha3": "TGO", "numeric": "768", "name": "Togo" },
    { "alpha2": "TK", "alpha3": "TKL", "numeric": "772", "name": "Tokelau" },
    { "alpha2": "TO", "alpha3": "TON", "numeric": "776", "name": "Tonga" },
    { "alpha2": "TT", "alpha3": "TTO", "numeric": "780", "name": "Trinidad and Tobago" },
    { "alpha2": "TN", "alpha3": "TUN", "numeric": "788", "name": "Tunisia" },
    { "alpha2": "TR", "alpha3": "TUR", "numeric": "792", "name": "Turkey" },
    { "alpha2": "TM", "alpha3": "TKM", "numeric": "795", "name": "Turkmenistan" },
    { "alpha2": "TC", "alpha3": "TCA", "numeric": "796", "name": "Turks and Caicos Islands" },
    { "alpha2": "TV", "alpha3": "TUV", "numeric": "798", "name": "Tuvalu" },
    { "alpha2": "UG", "alpha3": "UGA", "numeric": "800", "name": "Uganda" },
    { "alpha2": "UA", "alpha3": "UKR", "numeric": "804", "name": "Ukraine" },
    { "alpha2": "AE", "alpha3": "ARE", "numeric": "784", "name": "United Arab Emirates" },
    { "alpha2": "GB", "alpha3": "GBR", "numeric": "826", "name": "United Kingdom of Great Britain and Northern Ireland" },
    { "alpha2": "US", "alpha3": "USA", "numeric": "840", "name": "United States of America" },
    { "alpha2": "UY", "alpha3": "URY", "numeric": "858", "name": "Uruguay" },
    { "alpha2": "UZ", "alpha3": "UZB", "numeric": "860", "name": "Uzbekistan" },
    { "alpha2": "VU", "alpha3": "VUT", "numeric": "548", "name": "Vanuatu" },
    { "alpha2": "VE", "alpha3": "VEN", "numeric": "862", "name": "Venezuela (Bolivarian Republic of)" },
    { "alpha2": "VN", "alpha3": "VNM", "numeric": "704", "name": "Viet Nam" },
    { "alpha2": "VG", "alpha3": "VGB", "numeric": "092", "name": "Virgin Islands (British)" },
    { "alpha2": "VI", "alpha3": "VIR", "numeric": "850", "name": "Virgin Islands (U.S.)" },
    { "alpha2": "WF", "alpha3": "WLF", "numeric": "876", "name": "Wallis and Futuna" },
    { "alpha2": "EH", "alpha3": "ESH", "numeric": "732", "name": "Western Sahara" },
    { "alpha2": "YE", "alpha3": "YEM", "numeric": "887", "name": "Yemen" },
    { "alpha2": "ZM", "alpha3": "ZMB", "numeric": "894", "name": "Zambia" },
    { "alpha2": "ZW", "alpha3": "ZWE", "numeric": "716", "name": "Zimbabwe" }
  ];

  static alpha3To2 = CountryCodeConverter.countryCodes.reduce((acc, country) => {
    acc[country.alpha3] = country.alpha2;
    return acc;
  }, {});

  static convertCountryCodeAlpha3To2(alpha3) {
    return CountryCodeConverter.alpha3To2[alpha3];
  }
}