<template>
  <div class="modal modal-lg fade" tabindex="-1" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="title-event-color" :style="eventColor"></div>
        <div class="modal-header event-modal-header">
          <h1 class="modal-title fs-5">{{ title }}</h1>
          <div class="ms-4">
            <a :href="eventLink" @click="copyToClipBoard(eventLink, $event)" data-toggle="tooltip" data-placement="top"
              title="Copy">
              {{ i18n('calendars.event_link') }}
            </a>
          </div>

          <div class="ms-4" v-if="schedulerLink">
            <a :href="schedulerLink" @click="copyToClipBoard(schedulerLink, $event)" data-toggle="tooltip"
              data-placement="top" title="Copy">
              {{ i18n('calendars.scheduling_link') }}
            </a>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" data-controller="address-lookup">
          <div class="row mb-2">
            <div class="col-3">
              <label class="col-form-label">{{ i18n('activerecord.attributes.order.external_order_id') }}</label>
            </div>
            <div class="col-9">
              <input type="text" :value="external_order_id" class="form-control" disabled>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3"><label for="contact_person_firstname" class="col-form-label">{{
          i18n('activerecord.attributes.task.contact_person_firstname') }} *</label></div>
            <div class="col-md"><input type="text" v-model="contact_person_firstname" name="contact_person_firstname"
                class="form-control" required /></div>
            <div class="col-md-3"><label for="contact_person_lastname" class="col-form-label">{{
          i18n('activerecord.attributes.task.contact_person_lastname') }} *</label></div>
            <div class="col-md"><input type="text" v-model="contact_person_lastname" name="contact_person_lastname"
                class="form-control" required /></div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3"><label for="contact_person_company_name" class="col-form-label">{{
          i18n('activerecord.attributes.task.contact_person_company_name') }}</label>
            </div>
            <div class="col-md"><input type="text" v-model="contact_person_company_name"
                name="contact_person_company_name" class="form-control" /></div>
            <div class="col-md-3">
              <label for="contact_person_phone_number" class="col-form-label">{{
          i18n('activerecord.attributes.task.contact_person_phone_number') }}</label>
            </div>
            <div class="col-md">
              <input type="text" v-model="contact_person_phone_number" name="contact_person_phone_number"
                class="form-control" />
              <!-- click to call -->
              <a :href="'tel:' + contact_person_phone_number" v-if="contact_person_phone_number" class="mt-2">
                {{ i18n('calendars.click_to_call') }} <i class="bi bi-telephone"></i>
              </a>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3"><label for="contact_person_email" class="col-form-label">{{
          i18n('activerecord.attributes.task.contact_person_email') }}</label></div>
            <div class="col-md"><input type="text" v-model="contact_person_email" name="contact_person_email"
                class="form-control" /></div>
            <div class="col-md-3"><label for="commissioned_by" class="col-form-label">{{
          i18n('activerecord.attributes.task.commissioned_by') }}</label></div>
            <div class="col-md"><input type="text" v-model="commissioned_by" name="commissioned_by" class="form-control"
                disabled /></div>
          </div>
          <hr />
          <div data-controller="licenseplate-lookup">
            <div class="row mb-2" v-for="custom_task_field in custom_task_fields">
              <div class="col-md-3"><label :for="custom_task_field.name" class="col-form-label">{{
          custom_task_field.name }}</label></div>
              <div class="col-md">
                <input type="text" v-model="custom_task_field.value" v-bind="licensePlateAttributes(custom_task_field)"
                  class="form-control">
              </div>
            </div>
          </div>
          <hr />
          <div class="mb-2 row align-items-center flex-row ">
            <div class="col-md-2"><label for="contact_person_postal_code">{{
          i18n('activerecord.attributes.task.contact_person_postal_code') }}
                *</label></div>
            <div class="col-md">
              <input type="text" v-model="contact_person_postal_code" data-address-lookup-target="postalcode"
                data-action="blur->address-lookup#lookup" name="contact_person_postal_code" class="form-control"
                required />
            </div>
            <div class="col-md"><label for="contact_person_house_number">{{
          i18n('activerecord.attributes.task.contact_person_house_number') }}
                *</label></div>
            <div class="col-md">
              <input type="text" v-model="contact_person_house_number" data-address-lookup-target="housenumber"
                data-action="blur->address-lookup#lookup" name="contact_person_house_number" class="form-control"
                required />
            </div>
          </div>
          <div class="mb-2 row align-items-center">
            <div class="col-md-2"><label for="contact_person_street">{{
          i18n('activerecord.attributes.task.contact_person_street') }} *</label>
            </div>
            <div class="col-md">
              <input type="text" v-model="contact_person_street" data-address-lookup-target="street"
                name="contact_person_street" class="form-control" required />
            </div>
            <div class="col-md-1"><label for="contact_person_city">{{
          i18n('activerecord.attributes.task.contact_person_city') }} *</label></div>
            <div class="col-md">
              <input type="text" v-model="contact_person_city" data-address-lookup-target="city"
                name="contact_person_city" class="form-control" required />
            </div>
          </div>
          <div class="text-end" v-if="gapi_link">
            <a :href="gapi_link" target="_blank">{{ i18n('calendars.gapi_link') }}</a>
          </div>
          <hr />
          <div class="row mb-2">
            <div class="col-md-2"><label class="col-form-label" for="date">{{ i18n('activerecord.attributes.task.date')
                }}
              </label></div>
            <div class="col-md"><input type="date" v-model="date" name="date" class="form-control" /></div>
          </div>
          <div class="row mb-2">
            <div class="col-md-2">
              <label class="col-form-label" for="start_time">
                <span v-if="event?.extendedProps?.from_suggestion">
                  <i class="bi bi-star-fill icon-gold icon-shadow"></i>
                </span> {{ i18n('activerecord.attributes.task.starts_at') }}
              </label>
            </div>
            <div class="col-md"><input type="time" v-model="startTime" name="start_time" class="form-control" /></div>
            <div class="col-md-2">
              <label class="col-form-label" for="end_time">
                <span v-if="event?.extendedProps?.from_suggestion">
                  <i class="bi bi-star-fill icon-gold icon-shadow"></i>
                </span> {{ i18n('activerecord.attributes.task.ends_at') }}
              </label>
            </div>
            <div class="col-md"><input type="time" v-model="endTime" name="end_time" class="form-control" /></div>
          </div>
          <div v-if="(status == 'pending' || status == 'pending_reschedule') && this.suggestions.length == 0"
            class="text-end">
            <a v-if="status == 'pending' || status == 'pending_reschedule'" href="javascript:;" type="button" class=""
              :disabled="suggestionsLoading" @click="getSuggestions">
              <span v-if="suggestionsLoading" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              {{ i18n('calendars.get_suggestions') }}
            </a>
          </div>
          <div v-if="installationAgentDistancesLoading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div>
              <small>
                <span>{{ i18n('calendars.agent_distances') }}</span>
                <span class="mx-1 text-info" data-bs-toggle="tooltip" data-bs-placement="bottom"
                  :title="i18n('calendars.agent_distances_info')">
                  <i class="bi bi-info-circle"></i>
                </span>:
              </small>
            </div>
            <div class="d-flex">
              <div v-for="agent_distances in installationAgentDistances">
                <div v-if="agent_distances['routes'].length > 0" class="badge rounded-pill bg-light text-dark">
                  <span>{{ agent_distances['agent']['firstname'] }}:</span>
                  {{ Math.floor(parseInt(agent_distances['routes'][0]['sections'][0]['summary']['length']) / 1000.0) }}
                  km
                </div>
              </div>
            </div>
          </div>
          <div v-if="suggestions.length > 0">
            <hr>
            <h5>{{ i18n('calendars.suggestions') }}</h5>
            <div class="d-flex flex-wrap">
              <div v-for="suggestion in suggestions" class="m-2 card">
                <a class="card-body p-2 text-decoration-none" href="javascript:;"
                  :title="i18n('calendars.suggestion_tooltip')" @click="setSuggestion(suggestion)">
                  <div class="small">
                    {{ moment(suggestion.start).format('dd D MMM') }}<br>
                    {{ moment(suggestion.start).format('HH:mm') }} - {{ moment(suggestion.stop).format('HH:mm') }}
                  </div>
                  <div class="small">
                    {{ moment(suggestion.to_time).format('HH:mm') }} - {{ moment(suggestion.from_time).format('HH:mm')
                    }}
                  </div>
                  <div class="small fw-bold">
                    <span v-for="agent_id in suggestion.agent_ids">
                      {{ getAgentById(agent_id).title }}
                    </span>
                  </div>
                  <div>
                    {{ suggestion.score || suggestion.total_score }}
                    {{ Object.values(suggestion.agent_slots).reduce((sum, { traveltime }) => sum + traveltime.to +
          traveltime.from, 0) }}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div class="row mb-2">
            <div class="col-md-2"><label class="col-form-label">{{ i18n('activerecord.attributes.task.status')
                }}</label>
            </div>
            <div class="col-md">
              <select v-model="status" class="form-select">
                <option>pending</option>
                <option>customer_scheduled</option>
                <option>pending_reschedule</option>
                <option>pending_customer_confirmation</option>
                <option>confirmed</option>
                <option>in_progress</option>
                <option>completed</option>
                <option>cancelled</option>
                <option>failed</option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="event">
            <div class="col-md-2">
              <label class="col-form-label">{{ i18n('activerecord.attributes.task.user') }}</label>
            </div>
            <div>
              <select v-if="users.length > 0" v-model="user" class="form-select">
                <option v-for="usr in users" :value="{ id: usr.id, title: usr.title }">{{ usr.title }}</option>
              </select>
            </div>
          </div>
          <div><label class="col-form-label" for="notes">{{ i18n('activerecord.attributes.task.notes') }}</label></div>
          <div class="row mb-2" v-for="note in filteredNotes">
            <div class="col-md">
              <div class="input-group">
                <textarea v-model="note.description" name="notes" class="form-control" rows="7"></textarea>
                <button class="btn btn-outline-danger input-group-button" @click="deleteNote(note)">x</button>
              </div>
            </div>
          </div>
          <div class="mb-2 text-end">
            <a href="javascript:;" @click="addNote">{{ i18n('note.actions.new') }}</a>
          </div>
        </div>
        <div class="p-3">
          <div class="d-flex justify-content-between gap-2">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ i18n('close') }}</button>
            <div v-if="startTime || endTime || date">
              <button type="button" class="btn btn-warning" @click="setToPending">{{ i18n('calendars.set_to_pending')
                }}</button>
            </div>
            <button type="button" class="btn btn-danger" @click="destroy">{{ i18n('delete') }}</button>
            <button type="button" class="btn btn-primary" @click="update" data-bs-dismiss="modal">{{ i18n('save')
              }}</button>
          </div>
          <div v-if="status == 'customer_scheduled'" class="pt-3">
            <hr>
            <button type="button" class="btn btn-success w-100" @click="confirm"
              data-turbo_confirm="{{i18n('confirm_text')}}" data-bs-dismiss="modal">Confirm</button>
          </div>
          <div v-if="status == 'pending_customer_confirmation'" class="pt-3">
            <hr>
            <button type="button" class="btn btn-warning w-100" @click="confirmationRequest"
              data-turbo_confirm="{{i18n('confirm_text')}}" data-bs-dismiss="modal">Send customer confirmation
              mail</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
.input-group-button {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
}

.title-event-color {
  height: 8px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
}

.fc-timegrid-event-harness:hover {
  z-index: 10 !important;
}
</style>

<script>
import moment from 'moment/dist/moment'
import nl_moment from 'moment/dist/locale/nl'
import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";
import settings_users_controller from '../controllers/settings_users_controller';

const i18n = new I18n(en);

export default {
  props: {
    event: {
      type: Object,
      required: false,
      default: () => { }
    },
    users: {
      type: Array,
      required: false,
      default: () => []
    },
    schedulingServiceRequestBaseUrl: {
      type: String,
      required: false
    },
    // Deprecated 21/12/2022
    'scheduling-base-url': {
      type: String,
      required: false
    },
  },
  watch: {
    event() {
      this.suggestions = []
      if (this.event) {
        bootstrap.Modal.getOrCreateInstance(this.$refs.modal).show()
        if (this.event.start) {
          this.date = moment(this.event.start).format('YYYY-MM-DD')
          this.startTime = moment(this.event.start).format('HH:mm')
        } else {
          this.date = null
          this.startTime = null
        }

        if (this.event.end) {
          this.endTime = moment(this.event.end).format('HH:mm')
        } else {
          this.endTime = null
        }


        this.status = this.event.extendedProps.status
        let resource = this.event.getResources ? this.event.getResources()[0] : { id: this.event.resourceId, title: null }
        this.user = { id: resource.id, title: resource.title }

        if (!resource.title && this.users.length > 0) {
          let user = this.users.find(u => u.id == resource.id)
          this.user = { id: user.id, title: user.title }
        }

        this.notes = this.event.extendedProps.notes

        this.external_order_id = this.event.extendedProps.external_order_id

        this.commissioned_by = this.event.extendedProps.commissioned_by

        this.contact_person_company_name = this.event.extendedProps.contact_person_company_name
        this.contact_person_firstname = this.event.extendedProps.contact_person_firstname
        this.contact_person_lastname = this.event.extendedProps.contact_person_lastname
        this.contact_person_phone_number = this.event.extendedProps.contact_person_phone_number
        this.contact_person_email = this.event.extendedProps.contact_person_email
        this.contact_person_postal_code = this.event.extendedProps.contact_person_postal_code
        this.contact_person_house_number = this.event.extendedProps.contact_person_house_number
        this.contact_person_street = this.event.extendedProps.contact_person_street
        this.contact_person_city = this.event.extendedProps.contact_person_city

        this.custom_task_fields = this.event.extendedProps.custom_task_fields
      } else {
        bootstrap.Modal.getOrCreateInstance(this.$refs.modal).hide()
        this.date = null
        this.startTime = null
        this.endTime = null
        this.status = null
        this.user = null

        this.external_order_id = null

        this.contact_person_company_name = null
        this.contact_person_firstname = null
        this.contact_person_lastname = null
        this.contact_person_phone_number = null
        this.contact_person_email = null
        this.commissioned_by = null
        this.contact_person_postal_code = null
        this.contact_person_house_number = null
        this.contact_person_street = null
        this.contact_person_city = null

        this.custom_task_fields = []

        this.notes = []
      }

      // Get the distances for the different installation agents if the postal code and house number are set
      if (this.contact_person_postal_code && this.contact_person_house_number) {
        this.getInstallationAgentDistances()
      }
    },
  },
  data() {
    return {
      moment: moment,
      startTime: null,
      endTime: null,
      status: null,
      date: null,
      user: null,
      external_order_id: null,
      contact_person_company_name: null,
      contact_person_firstname: null,
      contact_person_lastname: null,
      contact_person_phone_number: null,
      contact_person_email: null,
      commissioned_by: null,
      contact_person_postal_code: null,
      contact_person_house_number: null,
      contact_person_street: null,
      contact_person_city: null,
      custom_task_fields: [],
      notes: [],
      locale: window.locale,
      suggestions: [],
      suggestionsLoading: false,
      installationAgentDistances: {},
      installationAgentDistancesLoading: false,
    }
  },
  methods: {
    update() {
      this.$emit('save', this.updatedEvent)
    },
    destroy() {
      if (confirm(this.i18n('confirm_text')) == true) {
        this.$emit('destroy', this.event)
      }
    },
    confirm() {
      this.$emit('confirm', this.updatedEvent)
    },
    confirmationRequest() {
      this.$emit('confirmationRequest', this.updatedEvent)
    },
    close() {
      this.$emit('close')
    },
    i18n(key) {
      return i18n.t(key)
    },
    addNote() {
      this.notes.push({ description: '' })
    },
    deleteNote(note) {
      if (note.id) {
        note._destroy = true
      } else {
        this.notes = this.notes.filter(n => n != note)
      }
    },
    copyToClipBoard(text, event) {
      navigator.clipboard.writeText(text)
      if (event) {
        event.preventDefault()
        event.stopPropagation();
      }
      let oldTitle = event.target.title

      event.target.title = this.i18n('clipboard_copied')

      bootstrap.Tooltip.getOrCreateInstance(event.target).show()

      event.target.addEventListener('hidden.bs.tooltip', (e) => {
        bootstrap.Tooltip.getOrCreateInstance(e.target).dispose()
        e.target.title = oldTitle
      }, { once: true })

      setTimeout(() => {
        bootstrap.Tooltip.getOrCreateInstance(event.target).hide()
        event.target.title = oldTitle
      }, 1000)

      return false
    },
    licensePlateAttributes(ctf) {
      if (ctf.field_type == 'license_plate') {
        return {
          'data-licenseplate-lookup-target': 'licenseplate',
          'data-action': 'input->licenseplate-lookup#input',
        }
      } else {
        if (ctf.api_identifier.includes('brand') || ctf.api_identifier.includes('merk')) {
          return {
            'data-licenseplate-lookup-target': 'brand'
          }
        } else if (ctf.api_identifier.includes('model')) {
          return {
            'data-licenseplate-lookup-target': 'model'
          }
        }
      }

      return {}
    },
    getAgentById(agent_id) {
      return this.users ? this.users.find(u => u.id == agent_id) : null
    },
    getSuggestions() {
      this.suggestionsLoading = true
      let simpleTasks = [this.event.id];
      fetch('/availability/suggestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          tasks: simpleTasks
        })
      }).then(response => {
        return response.json()
      }).then(data => {
        this.suggestionsLoading = false
        this.suggestions = data
      })
    },
    setSuggestion(suggestion) {
      this.date = suggestion.date
      this.startTime = moment(suggestion.start).format('HH:mm')
      this.endTime = moment(suggestion.stop).format('HH:mm')
      let user = this.getAgentById(suggestion.agent_ids[0])
      this.user = { id: user.id, title: user.title }
      if (this.status == 'pending' || this.status == 'pending_reschedule') {
        this.status = 'customer_scheduled'
      }
    },
    getInstallationAgentDistances() {
      this.installationAgentDistancesLoading = true
      fetch('/api/v1/location/check_agent_distances?task_id=' + this.event.id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }).then(response => {
        return response.json()
      }).then(data => {
        this.installationAgentDistancesLoading = false
        this.installationAgentDistances = data
      })
    },
    setToPending() {
      this.status = 'pending'
      // Clear date and time as well
      this.date = null
      this.startTime = null
      this.endTime = null
      this.user = null
    }
  },
  computed: {
    eventType() {
      return this.event?.type
    },
    title() {
      return this.event?.title
    },
    gapi_link() {
      let gapi_url = new URL('/maps/search/?api=1', 'https://www.google.com')
      let gapi_params = new URLSearchParams(gapi_url.search)
      gapi_params.append('query', this.contact_person_city + ', ' + this.contact_person_street + ' ' + this.contact_person_house_number + ', ' + this.contact_person_postal_code)
      gapi_url.search = gapi_params.toString()
      return gapi_url.toString()
    },
    eventColor() {
      if (this.event && this.event.borderColor) {
        return {
          backgroundColor: this.event.borderColor
        }
      }
    },
    updatedEvent() {
      const event = JSON.parse(JSON.stringify(this.event))

      if (this.startTime && this.date) {
        event.start = moment(new Date(this.date + ' ' + this.startTime))
      } else {
        event.start = null
      }

      console.log('end', this.endTime, this.date)

      if (this.endTime && this.date) {
        event.end = moment(new Date(this.date + ' ' + this.endTime))
      } else {
        console.log('end', event.end)

        event.end = null
      }

      return {
        event: {
          id: event?.id,
          starts_at: (event.start || (this.date && this.startTime)) ? moment(event.start).set({ 'year': this.date.split('-')[0], 'month': parseInt(this.date.split('-')[1]) - 1, 'date': this.date.split('-')[2], 'hour': this.startTime.split(':')[0], 'minute': this.startTime.split(':')[1] }).toDate() : null,
          ends_at: (event.end || (this.date && this.endTime)) ? moment(event.end).set({ 'year': this.date.split('-')[0], 'month': parseInt(this.date.split('-')[1]) - 1, 'date': this.date.split('-')[2], 'hour': this.endTime.split(':')[0], 'minute': this.endTime.split(':')[1] }).toDate() : null,
          status: this.status,
          user_id: this.user?.id,
          contact_person_company_name: this.contact_person_company_name,
          contact_person_firstname: this.contact_person_firstname,
          contact_person_lastname: this.contact_person_lastname,
          contact_person_phone_number: this.contact_person_phone_number,
          contact_person_email: this.contact_person_email,
          commissioned_by: this.commissioned_by,
          contact_person_postal_code: this.contact_person_postal_code,
          contact_person_house_number: this.contact_person_house_number,
          contact_person_street: this.contact_person_street,
          contact_person_city: this.contact_person_city,
          parameters_json: JSON.stringify(this.customTaskFieldsToObject),
          notes_attributes: this.notes.map(note => {
            return {
              id: note.id,
              description: note.description,
              _destroy: note._destroy
            }
          })
        }
      }
    },
    filteredNotes() {
      if (this.event) {
        return this.notes.filter(note => !note._destroy)
      } else {
        return []
      }
    },
    customTaskFieldsToObject() {
      let json = {}

      this.custom_task_fields.forEach(field => {
        json[field.api_identifier] = field.value
      })

      return json
    },
    eventLink() {
      if (this.event) {
        return location.protocol + '//' + location.host + "/calendars" + "?task_id=" + this.event.id
      }
    },
    schedulerLink() {
      if (this.schedulingServiceRequestBaseUrl && this.event?.extendedProps?.order_tasks_request_ref) {
        return this.schedulingServiceRequestBaseUrl + "/" + this.event.extendedProps.order_tasks_request_ref
      }

      if (this.schedulingBaseUrl && this.event?.extendedProps?.order_ref) {
        return this.schedulingBaseUrl + "/" + this.event.extendedProps.order_ref
      }
    }
  },
  mounted() {
    this.$refs.modal.addEventListener('hidden.bs.modal', this.close)
    moment.locale(this.locale);
    if (this.locale == 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
      moment.locale('nl');
      moment.updateLocale('nl', nl_moment);
    }
  }
}

</script>