import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="client-portal-tasks-table"
export default class extends Controller {
  static targets = ["taskShow"]

  initialize() {
  }

  toggle(event) {
    const target = event.currentTarget
    const frameShowUrl = target.dataset.frameShowUrl
    const frameCloseUrl = target.dataset.frameCloseUrl
    const frame = document.getElementById(target.dataset.turboFrame)



    if (target.classList.contains('open')) {
      frame.src = frameCloseUrl
      // Remove open class from the row
      target.classList.remove('open')
    }
    else {
      frame.src = frameShowUrl
      // Set open class on the row
      target.classList.add('open')




      if (!target.classList.contains('event-listener-added')) {
        // Wait for the frame to load and scroll to it
        frame.addEventListener('turbo:frame-load', () => {

          if (!target.classList.contains('open')) {
            return
          }

          frame.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      }


      target.classList.add('event-listener-added')

    }
  }


  connect() {
    console.log("Client Portal Tasks Table Controller Connected")
  }

  disconnect() {
    console.log("Client Portal Tasks Controller Disconnected")
  }
}
