import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scheduler-information"
export default class extends Controller {
  static targets = ["allSameAddress", "atCompanyLocation", "singleAddressForm", "multipleAddressForm", "locationForm",
                    "street", "houseNumber", "postalCode", "city", "country"]
  static values = {
    defaultAddress: Object,
    defaultOption: String,
  }

  oldAddress = {};

  connect () {
    this.defaultOptionValue = this.defaultOptionValue || "all_same_address"

    switch (this.defaultOptionValue) {
      case "all_same_address":
        this.allSameAddressTarget.checked = true
        this.toggleAllSameAddress()
        break
      default:
        this.atCompanyLocationTarget.checked = true
        this.toggleAtCompanyLocation()
        break
    }
  }

  toggleAllSameAddress (event) {
    if(this.allSameAddressTarget.checked) {
      this.atCompanyLocationTarget.checked = false

      this.enableLocationForm()
      this.restoreOldAddress()
    }
  }

  toggleAtCompanyLocation (event) {
    if (this.atCompanyLocationTarget.checked) {
      this.allSameAddressTarget.checked = false

      this.prefillLocationForm()
    }
  }


  showMutlipleAddressForm () {
    this.multipleAddressFormTarget.classList.remove("d-none")
  }

  hideMutlipleAddressForm () {
    this.multipleAddressFormTarget.classList.add("d-none")
  }

  restoreOldAddress () {
    if(this.oldAddress["street"] !== undefined) {
      this.streetTarget.value = this.oldAddress["street"]
      this.houseNumberTarget.value = this.oldAddress["houseNumber"]
      this.postalCodeTarget.value = this.oldAddress["postalCode"]
      this.cityTarget.value = this.oldAddress["city"]
      this.countryTarget.value = this.oldAddress["country"]
    }
  }

  prefillLocationForm () {
    if(this.defaultAddressValue.postal_code !== this.postalCodeTarget.value && this.defaultAddressValue.houseNumber !== this.houseNumberTarget.value ) {
      this.oldAddress["street"] = this.streetTarget.value
      this.oldAddress["houseNumber"] = this.houseNumberTarget.value
      this.oldAddress["postalCode"] = this.postalCodeTarget.value
      this.oldAddress["city"] = this.cityTarget.value
      this.oldAddress["country"] = this.countryTarget.value
    }

    this.streetTarget.value = this.defaultAddressValue.street
    this.houseNumberTarget.value = this.defaultAddressValue.house_number
    this.postalCodeTarget.value = this.defaultAddressValue.postal_code
    this.cityTarget.value = this.defaultAddressValue.city
    this.countryTarget.value = this.defaultAddressValue.country

    this.disableLocationForm()
  }

  disableLocationForm () {
    this.streetTarget.readOnly = true
    this.houseNumberTarget.readOnly = true
    this.postalCodeTarget.readOnly = true
    this.cityTarget.readOnly = true
    this.countryTarget.readOnly = true

    for(let i=0; i < this.countryTarget.options.length; i++) {
      if(!this.countryTarget.options[i].selected) {
        this.countryTarget.options[i].disabled = true
      }
    }
  }

  enableLocationForm () {
    this.streetTarget.readOnly = false
    this.houseNumberTarget.readOnly = false
    this.postalCodeTarget.readOnly = false
    this.cityTarget.readOnly = false
    for(let i=0; i < this.countryTarget.options.length; i++) {
      this.countryTarget.options[i].disabled = false
    }
  }
}
