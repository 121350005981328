import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="settings-subTaskTypes"
export default class extends Controller {
  static targets = [ "selectable-subTaskType" ]

  connect() {
  }
  select (event) {
    this.setSelected(event.currentTarget);
  }
  deselect() {
    this['selectable-subTaskTypeTargets'].forEach((sub_task_typesTarget) => {
      sub_task_typesTarget.classList.remove("active");
    });
  }
  setSelected (currentTarget) {
    this['selectable-subTaskTypeTargets'].forEach((sub_task_typesTarget) => {
      if (currentTarget == sub_task_typesTarget) {
        sub_task_typesTarget.classList.add('active');
      } else {
        sub_task_typesTarget.classList.remove("active");
      }
    });
  }
}
