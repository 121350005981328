import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="settings-users"
export default class extends Controller {
  static targets = [ "selectable" ]

  connect() {
  }
  select (event) {
    this.setSelected(event.currentTarget);
  }
  setSelected (currentTarget) {
    this['selectableTargets'].forEach((agentcar) => {
      if (currentTarget == agentcar) {
        agentcar.classList.add('active');
      } else {
        agentcar.classList.remove("active");
      }
    });
  }
}
