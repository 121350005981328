import { Controller } from "@hotwired/stimulus"
import { createApp } from "vue/dist/vue.esm-bundler.js";
import Scheduler from '@/vue_components/scheduler.vue';
import { createI18n } from 'vue-i18n'

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["calendar"]

  connect() {
    console.log('calendar controller connected')
    this.mountCalendars()
  }

  mountCalendars() {
    this.calendarTargets.forEach(element => {
      if (!element.__vue_app__) {
        let calendar = createApp({
          components: {
            Scheduler: Scheduler
          },
          compilerOptions: {
            isCustomElement: tag => {
              tag.startsWith('turbo')
            }
          }
        })
        calendar.use(createI18n({}));
        calendar.mount(element)
      }
    });
  }

}
