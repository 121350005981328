<template>
  <div class="modal fade" tabindex="-1" ref="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{title}} - {{formatted_date}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
            <strong>{{i18n('calendars.start_location')}}</strong>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="start_location_postal_code">{{i18n('activerecord.attributes.user_date_start_end_location.start_location_postal_code')}}</label>
              <div class="col"><input type="text" v-model="start_location_postal_code" name="start_location_postal_code" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="start_location_house_number">{{i18n('activerecord.attributes.user_date_start_end_location.start_location_house_number')}}</label>
               <div class="col"><input type="text" v-model="start_location_house_number" name="start_location_house_number" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="start_location_street">{{i18n('activerecord.attributes.user_date_start_end_location.start_location_street')}}</label>
              <div class="col"><input type="text" v-model="start_location_street" name="start_location_street" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="start_location_city">{{i18n('activerecord.attributes.user_date_start_end_location.start_location_city')}}</label>
              <div class="col"><input type="text" v-model="start_location_city" name="start_location_city" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="start_location_country">{{i18n('activerecord.attributes.user_date_start_end_location.start_location_country')}}</label>
              <div class="col"><CountrySelect v-model="start_location_country" :country="start_location_country" name="start_location_country" class="form-control" :usei18n="false" /></div>
            </div>
          </div>
          <hr/>
          <div>
            <strong>{{i18n('calendars.end_location')}}</strong>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="end_location_postal_code">{{i18n('activerecord.attributes.user_date_start_end_location.end_location_postal_code')}}</label>
              <div class="col"><input type="text" v-model="end_location_postal_code" name="end_location_postal_code" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="end_location_house_number">{{i18n('activerecord.attributes.user_date_start_end_location.end_location_house_number')}}</label>
              <div class="col"><input type="text" v-model="end_location_house_number" name="end_location_house_number" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="end_location_street">{{i18n('activerecord.attributes.user_date_start_end_location.end_location_street')}}</label>
              <div class="col"><input type="text" v-model="end_location_street" name="end_location_street" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="end_location_city">{{i18n('activerecord.attributes.user_date_start_end_location.end_location_city')}}</label>
              <div class="col"><input type="text" v-model="end_location_city" name="end_location_city" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <label class="col-4 col-form-label" for="end_location_country">{{i18n('activerecord.attributes.user_date_start_end_location.end_location_country')}}</label>
              <div class="col"><CountrySelect v-model="end_location_country" :country="end_location_country" name="end_location_country" class="form-control" :usei18n="false" /></div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{i18n('close')}}</button>
          <button type="button" class="btn btn-primary" @click="update" data-bs-dismiss="modal">{{i18n('save')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";
import { CountrySelect } from 'vue3-country-region-select'

const i18n = new I18n(en);

export default {
  components: {
    CountrySelect: CountrySelect
  },
  props: {
    resource: {  
      type: Object,
      required: false,
      default: () => {}
    },
    date: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  watch: {
    resource () {
      if(this.resource) {
        bootstrap.Modal.getOrCreateInstance(this.$refs.modal).show()
        if(this.resource.extendedProps.user_date_start_end_locations.length > 0 && this.resource.extendedProps.user_date_start_end_locations[0]) {
          this.start_location_postal_code = this.resource.extendedProps.user_date_start_end_locations[0].start_location_postal_code
          this.start_location_house_number = this.resource.extendedProps.user_date_start_end_locations[0].start_location_house_number
          this.start_location_street = this.resource.extendedProps.user_date_start_end_locations[0].start_location_street
          this.start_location_city = this.resource.extendedProps.user_date_start_end_locations[0].start_location_city
          this.start_location_country = this.resource.extendedProps.user_date_start_end_locations[0].start_location_country
          this.end_location_postal_code = this.resource.extendedProps.user_date_start_end_locations[0].end_location_postal_code
          this.end_location_house_number = this.resource.extendedProps.user_date_start_end_locations[0].end_location_house_number
          this.end_location_street = this.resource.extendedProps.user_date_start_end_locations[0].end_location_street
          this.end_location_city = this.resource.extendedProps.user_date_start_end_locations[0].end_location_city
          this.end_location_country = this.resource.extendedProps.user_date_start_end_locations[0].end_location_country
        }
        
        this.start_location_country ||= 'NL'
        this.end_location_country ||= 'NL'

      } else {
        bootstrap.Modal.getOrCreateInstance(this.$refs.modal).hide()
        this.start_location_postal_code = null
        this.start_location_house_number = null
        this.start_location_street = null
        this.start_location_city = null
        this.start_location_country = null
        this.end_location_postal_code = null
        this.end_location_house_number = null
        this.end_location_street = null
        this.end_location_city = null
        this.end_location_country = null
      }
    }
  },
  data () {
    return {
      start_location_postal_code: null,
      start_location_city: null,
      start_location_street: null,
      start_location_house_number: null,
      start_location_country: null,
      end_location_postal_code: null,
      end_location_city: null,
      end_location_street: null,
      end_location_house_number: null,
      end_location_country: null,
      locale: window.locale,
    }
  },
  methods: {
    update () {
      this.$emit('save', {
        id: this.resource.id,
        date: this.date,
        user_date_start_end_location_id: this.resource.extendedProps.user_date_start_end_locations[0]?.id,
        start_location_postal_code: this.start_location_postal_code,
        start_location_city: this.start_location_city,
        start_location_street: this.start_location_street,
        start_location_house_number: this.start_location_house_number,
        start_location_country: this.start_location_country,
        end_location_postal_code: this.end_location_postal_code,
        end_location_city: this.end_location_city,
        end_location_street: this.end_location_street,
        end_location_house_number: this.end_location_house_number,
        end_location_country: this.end_location_country,
      })
    },
    close () {
      this.$emit('close')
    },
    i18n (key) {
      return i18n.t(key)
    },
  },
  computed: {
    title () {
      return this.resource?.title
    },
    formatted_date () {
      if(this.date) {
        return moment(this.date).format('L')
      }

      return ''
    }
  },
  mounted () {
    this.$refs.modal.addEventListener('hidden.bs.modal', this.close)
    moment.locale(this.locale);

    if(this.locale == 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }
  }
}

</script>