<template>
  <div class="mb-2" v-html="this.custom_adres_html"></div>
  <div v-if="!this.custom_adres_html" class="text-center w-100 mb-3">
    <div>Loading...</div>
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <button @click="this.$parent.$parent.concepts=[]" class="btn btn-secondary w-100">{{this.i18n("cancel")}}</button>
</template>

<script>
import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";
import { nextTick } from 'vue'

const i18n = new I18n(en);

export default {
  name: "location_card_sub_planned",
  props: {
    concept: Object
  },
  data(){
    if(this.$parent.locale === 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }

    return {
      custom_adres_html: null
    }
  },
  mounted() {
    let task_ids = [];
    for (const task of this.concept.tasks) task_ids.push(task.id);

    fetch("sub_form_contact?id="+JSON.stringify(task_ids), {
      method: 'GET',
      headers: {}
    })
        .then((response)=> {return  response.text()})
        .then((data) => {
          this.custom_adres_html = data;
          nextTick(()=> document.getElementById("submit").onclick =  this.submit_task_adres_from)
        });
  },
  methods:{
    submit_task_adres_from(e){
      //Making call async so forms submits regardless
      const int = setInterval(()=>{
        if(!e.target.disabled){
          clearInterval(int);
          this.$parent.setStep(1);
        }
      },100)
    },
    i18n (key) {
      return i18n.t(key)
    },
  }
}
</script>

<style scoped>

</style>