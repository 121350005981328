<template>

    <canvas
        @wheel="scrollEvent"
        @mousemove="mouseMove"
        @mouseout="mouseOut"
        @mousedown="mouseClick"
        @dblclick="mouseDblClick"
        :width="this.canvas.width"
        :height="this.canvas.height"
        ref="chart"></canvas>
</template>

<script>

import moment from 'moment/min/moment-with-locales';

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const typeEnum = {day: 0,week: 1,month: 2}


const millisDay = 1000*60*60*24;
const millisWeek = millisDay*7;
const millisMonth = millisDay*30.44;

const millisArray = [millisDay,millisWeek,millisMonth];
const maxArray = [31,52,12];


export default {
  name: "kpiDateranger",
  props: {
    callback: {
      type: Function,
      required: true
    },
    frames: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    }
  },
  data(){
    const defaultWidth = 700;
    const typeID = typeEnum.month;

    const maxElement = maxArray[typeID];
    const selectedMillis = millisArray[typeID];
    let random_data = {};


    const startD = new Date(2023,0,1);
    // for (let i = -500; i < 500; i++) {
    //   random_data[new Date(startD.getTime()+(i*selectedMillis)).getTime()] = Math.random()*30;
    // }
    let endD = new Date(startD.getTime()+(selectedMillis*maxElement));



    return {
      canvas:{
        width: defaultWidth,
        height: 60
      },
      dateRange:{
        start: startD,
        end: endD
      },
      bounds:{
        start: 0,
        end: 0
      },
      data: random_data,
      ctx: null,
      millisPerUnit: selectedMillis,
      typeID: typeID,
      lastUpdate: 0,
    };
  },
  watch: {
    frames: {
      immediate: false,
      handler (val, oldVal) {
        let dateProcessed = {};
        let highestValue = 0;
        for (const monthVal of Object.keys(val)) {
          let year = parseInt(monthVal.split('_')[0]);
          let month = parseInt(monthVal.split('_')[1]);
          let date = new Date(year,month,1);

          if(highestValue < val[monthVal].task_count){
            highestValue = val[monthVal].task_count;
          }

          dateProcessed[date.getTime()+this.millisPerUnit] = val[monthVal].task_count;


        }
        const factor = 40/highestValue;
        for (const datum of Object.keys(dateProcessed)) {
          dateProcessed[datum] = Math.max(5,dateProcessed[datum]*factor);
        }
        this.data = dateProcessed;
        this.draw();
      }
    }
  },
  methods: {
    pixelXtoDate(x){
      const percentage = x/this.canvas.width;
      const startEp =this.dateRange.start.getTime();
      const endEp = this.dateRange.end.getTime();
      const epoch = startEp+((endEp-startEp)* percentage);
      return new Date(epoch);
    },
    dateToPixelX(date){
      const dateEp = date.getTime();
      const startEp = this.dateRange.start.getTime();
      const endEp = this.dateRange.end.getTime();

      let percentage = (dateEp - startEp)/(endEp-startEp);
      return this.canvas.width*percentage;
    },
    cursorPosition(x,y){
      const rect = this.$refs.chart.getBoundingClientRect()
      const nx = x - rect.left;
      const ny = y - rect.top;
      return {x: nx,y: ny}
    },
    getWeekNumber(d) {
      d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
      let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return  Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    },
    scrollEvent(e){
      const startEp = this.dateRange.start.getTime();
      const endEp = this.dateRange.end.getTime();
      const addTime = (endEp-startEp)*(e.wheelDeltaY/1000);
      this.dateRange.start = new Date(startEp + addTime);
      this.dateRange.end = new Date(endEp + addTime);
      this.draw();
      this.lastUpdate = Date.now();
      e.preventDefault();
    },
    mouseDblClick(e){
      if(this.bounds.start == 0 || this.bounds.end == 0){
        let dateX = this.pixelXtoDate(this.cursorPosition(e.clientX,e.clientY).x);
        this.switchScale(dateX);
      }
      this.bounds.start = 0;
      this.bounds.end = 0;
      this.lastUpdate = Date.now();
      this.draw();
      e.preventDefault();
    },
    switchScale(dateX){
      if(this.typeID >= 1){
        this.typeID = 0;
        let dateStartBound = new Date(dateX.getTime() - (1000*60*60*24*15));
        let dateEndBound = new Date(dateX.getTime() + (1000*60*60*24*15));
        this.dateRange.start = dateStartBound;
        this.dateRange.end = dateEndBound;
        this.millisPerUnit = millisArray[0];
      }else{
        this.typeID = 2;
        let dateStartBound = new Date(dateX.getTime() - (1000*60*60*24*182.5));
        let dateEndBound = new Date(dateX.getTime() + (1000*60*60*24*182.5));
        this.dateRange.start = dateStartBound;
        this.dateRange.end = dateEndBound;
        this.millisPerUnit = millisArray[2];
      }
    },
    mouseClick(e){
      let dateX = this.pixelXtoDate(this.cursorPosition(e.clientX,e.clientY).x);
      let distanceStart = Math.abs((dateX.getTime()-this.bounds.start)/this.millisPerUnit);
      let distanceEnd = Math.abs((dateX.getTime()-this.bounds.end)/this.millisPerUnit);

      let movePrev = 0;
      if(this.bounds.start !=0 && this.bounds.end !=0){
        if(distanceStart < distanceEnd){
          movePrev = 1;
        }else{
          movePrev = 2;
        }
      }else{
        this.bounds.start = dateX;
      }
      let upr = this.updateRenderer;
      const movelistener = (event) => {
        let newDateX = this.pixelXtoDate(this.cursorPosition(event.clientX,event.clientY).x);
        if(movePrev == 1){
          this.bounds.start = newDateX;
        }else{
          this.bounds.end = newDateX;
        }
        this.lastUpdate = Date.now();
        this.draw();
      }

      this.$refs.chart.addEventListener('mousemove', movelistener);
      const upListener = (event) => {
        this.$refs.chart.removeEventListener("mousemove",movelistener);
        const diff_start_end = Math.abs(this.bounds.end-this.bounds.start)
        if(diff_start_end < this.millisPerUnit*0.1 || this.bounds.end == 0){
          let newDateX = this.pixelXtoDate(this.cursorPosition(event.clientX,event.clientY).x);
          this.bounds.start = 0;
          this.bounds.end = 0;
          this.switchScale(newDateX);
        }
        this.$refs.chart.removeEventListener("mouseup",upListener);
        upr();
        this.draw();
      };
      this.$refs.chart.addEventListener('mouseup', upListener);
    },
    mouseMove(e){
      const dateMouse = this.pixelXtoDate(this.cursorPosition(e.clientX,e.clientY).x);
      this.draw(dateMouse);
    },
    mouseOut(e){
      this.draw(null);
    },
    draw(mouseDate = null){
      if(!this.ctx) return;
      this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
      let squares = Math.ceil((this.dateRange.end.getTime()-this.dateRange.start.getTime())/this.millisPerUnit);
      const margin = 5;
      let math = (this.canvas.width-(margin*(squares+1)))/squares;
      let divisor = 1;
      if(math < 10){
        divisor = 5;
      }else if(math < 30){
        divisor = 3;
      }



      for (let i = 0; i < squares+1; i++) {
        const dateBox = new Date((Math.floor(this.dateRange.start.getTime()/this.millisPerUnit)*this.millisPerUnit)+this.millisPerUnit*(i));
        const dateBoxNext = new Date((Math.floor(this.dateRange.start.getTime()/this.millisPerUnit)*this.millisPerUnit)+this.millisPerUnit*(i+1));

        const pixelDateBox = this.dateToPixelX(dateBox);

        this.ctx.fillStyle = "#EF6423";
        if(mouseDate != null && dateBox < mouseDate && mouseDate.getTime() <  dateBox.getTime()+this.millisPerUnit){
          this.ctx.fillStyle = "#c94100";
        }
        let val = 0;

        for (let datesInData in (this.data)){
          if(parseInt(datesInData) > dateBox.getTime() && parseInt(datesInData) < dateBoxNext.getTime()){
            val = this.data[datesInData]
            break;
          }
        }
        this.ctx.fillRect(pixelDateBox,(0.75*this.canvas.height),math,-val);
        let timeUnit = 0;
        if(this.typeID == 0){
            timeUnit = dateBox.getDate();
        }else if (this.typeID == 1){
          timeUnit = this.getWeekNumber(dateBox);
        }else if (this.typeID == 2){
          timeUnit = dateBox.getMonth();
        }
        if(timeUnit % divisor == 0){
          let text = timeUnit+"";
          if (this.typeID == 2){
            text = monthNames[timeUnit];
          }
          let text_middle_margin =  (math-this.ctx.measureText(text).width)/2;
          this.ctx.fillText(text, pixelDateBox+text_middle_margin, this.canvas.height*0.95);
        }
      }
      const nowDatePixel = this.dateToPixelX(new Date())
      this.ctx.fillStyle = "#FFF"
      this.ctx.fillRect(nowDatePixel-1,0,3, this.canvas.height);
      this.ctx.fillStyle = "#F00"
      this.ctx.fillRect(nowDatePixel,0,1, this.canvas.height);
      this.ctx.fillText("Today", nowDatePixel+5, this.canvas.height*0.2);
      if(this.bounds.start !== 0 && this.bounds.end !== 0){
        this.ctx.fillStyle = "#FFFFFF";
        this.ctx.globalAlpha = 0.5;
        let start =this.dateToPixelX(this.bounds.start);
        let end = this.dateToPixelX(this.bounds.end);
        this.ctx.fillRect(0,0,Math.min(start,end), (0.75*this.canvas.height));
        this.ctx.fillRect(Math.max(Math.max(start,end),0),0,this.canvas.width,(0.75*this.canvas.height));
        this.ctx.globalAlpha = 1.0;
        this.ctx.fillStyle = "#000"
        this.ctx.fillRect(start,0,2, this.canvas.height);
        this.ctx.fillRect(end,0,2, this.canvas.height);

        const dateStartStr = this.bounds.start.toLocaleDateString("nl-NL");

        this.ctx.fillText(dateStartStr,start-(5+this.ctx.measureText(dateStartStr).width),(0.2*this.canvas.height))
        this.ctx.fillText(this.bounds.end.toLocaleDateString("nl-NL"),end+5,(0.2*this.canvas.height))
      }

      for (let i = -1; i < 2; i++) {
        this.ctx.fillStyle = "#6c757d";
        this.ctx.font = "15px Arial";
        let dateBox2 = new Date(this.dateRange.start.getTime());
        let text = "";
        dateBox2.setDate(15)
        dateBox2.setHours(0);
        dateBox2.setMinutes(0)
        if(this.typeID !== 0){
          dateBox2.setMonth(6);

          dateBox2.setFullYear(dateBox2.getFullYear()+i);
          text = dateBox2.getFullYear().toString();
        }else{
          dateBox2.setMonth(dateBox2.getMonth()+i)
          text = monthNames[dateBox2.getMonth()];
        }
        this.ctx.fillText(text, this.dateToPixelX(dateBox2), this.canvas.height*0.20);
        this.ctx.font = "11px Arial";
      }
    },
    updateRenderer(){
      if(this.lastUpdate !== 0 && Date.now() - this.lastUpdate > 300){

        this.lastUpdate = 0;
        let bd_start = this.bounds.start;
        let bd_end = this.bounds.end;
        if(this.bounds.end == 0){
          bd_start = this.dateRange.start;
          bd_end = this.dateRange.end;
        }
        if(bd_start > bd_end){
          this.$props.callback(bd_end,bd_start);
        }else{
          this.$props.callback(bd_start,bd_end);
        }


      }
    }
  },
  created(){

    setInterval(this.updateRenderer,10)
  },
  mounted() {
    const canvas = this.$refs.chart;
    this.ctx = canvas.getContext("2d");
    this.draw();
    this.lastUpdate = Date.now()-301;
    this.updateRenderer();
  }
}
</script>

<style scoped>

</style>