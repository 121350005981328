<template>
  <div class="d-flex gap-2 flex-column" ref="kpi">
    <div><button v-for="entry in this.dataSet" @click="toggle(entry)" class="btn btn-sm m-1" :class="{'btn-primary' : entry.enabled,'btn-secondary' : !entry.enabled}">{{entry.display}}</button></div>
    <div>
      <canvas style="width: 100%; height: 60vh" ref="chart"></canvas>
    </div>
  </div>
</template>

<style lang="scss">
  @import './../../assets/stylesheets/default_theme.scss';

</style>

<script>

import moment from 'moment/min/moment-with-locales';

import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";
import Chart from 'chart.js/auto';
import colorLib from '@kurkle/color';

const i18n = new I18n(en);
const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

export default {
  props: {
    locale: {
      type: String,
      required: false,
      default: 'en'
    },
    agent_name:{
      type: String,
      required: true,
    },
    date_start: {
      type: Date,
      required: false,
      default: new Date(2022,8,11)
    },
    date_end: {
      type: Date,
      required: false,
      default: new Date(2023,1,10)
    }
  },
  data() {
    const stats = [

      // {key: 'traveldistance',display: 'travel distance',chart: 'line',stacked: false,unit: ''},
      {key: 'taskTime',perAgent: true,path: '/kpi/tasksTimeTotal?start='+this.date_start+'&end='+this.date_end,display: 'Task time',color: CHART_COLORS.green,chart: 'bar',stacked: false,unit: 'hours'},
      {key: 'agentload',perAgent: true,path: '/kpi/workLoad?start='+this.date_start+'&end='+this.date_end,display: 'Agent workload',color: CHART_COLORS.blue,chart: 'bar',stacked: false,unit: 'percentage'},
      {key: 'tasksStatus',perAgent: false,path: '/kpi/tasks?start='+this.date_start+'&end='+this.date_end,display: 'Tasks',color: CHART_COLORS.red,chart: 'bar',stacked: true,unit: 'tasks'},
      {key: 'tasksAgent',perAgent: true,path: '/kpi/tasks?groupby=true&start='+this.date_start+'&end='+this.date_end,display: 'Tasks per agent',color: CHART_COLORS.orange,chart: 'bar',stacked: true,unit: 'tasks'},
      // {key: 'agentloadTravel',display: 'Agent workload(inc. travel)'},
    ];

    for (let i = 0; i < stats.length; i++) {
      stats[i]['enabled'] = false;
    }

    return {
      dataSet: stats,
      agentName: JSON.parse(this.agent_name)
    }
  },
  methods: {
    /* Actions */
    toggle(slot) {
      slot.enabled = !slot.enabled;
      this.reloadData();
    },
    transparentize(value, opacity,darken = 0) {
      var alpha = opacity === undefined ? 0.5 : 1 - opacity;
      return colorLib(value).alpha(alpha).darken(darken).rgbString();
    },
    getWeekNumbers(){
      const momentStart= moment(this.date_start);
      const momentEnd= moment(this.date_end);
      let diff = momentEnd.diff(momentStart,'weeks');
      let weeknumbs = [];
      for (let i = 0; i < diff; i++) {
        weeknumbs.push( momentStart.add(1,'weeks').isoWeek())
      }
      return weeknumbs
    },
    reloadData(){
      this.chart.data.labels = this.getWeekNumbers();
      this.chart.data.datasets = [];
      let datesetIndex = 0;
      for (let i = 0; i < this.dataSet.length; i++) {

        const set = this.dataSet[i];

        if(set.enabled){
          if(set.path){
            fetch(set.path)
                .then(response => response.json())
                .then(data => {
                  if(set.perAgent){
                    let possibleAgents = {};
                    for (const dataRow in data) {
                      for(const agentKey in data[dataRow]){
                        possibleAgents[agentKey] = true;
                      }
                    }
                    for (const dataRow in data) {
                      for (const agentKey in possibleAgents) {
                        let val = data[dataRow][agentKey];
                        if (!val) {
                          val = 0;
                        }
                        if (Array.isArray(possibleAgents[agentKey])) {
                          possibleAgents[agentKey].push(val);
                        } else {
                          possibleAgents[agentKey] = [val]
                        }
                      }
                    }
                    let agentIndex = 0;
                    for (const agentKey in possibleAgents) {
                      agentIndex++;
                      this.chart.data.datasets.push({
                        label: set.display+'[%]'.replace('%',this.agentName[agentKey]),
                        data: possibleAgents[agentKey],
                        borderColor: set.color,
                        backgroundColor: this.transparentize(set.color,0.5,agentIndex/5.0),
                        stack: set.stacked ? set.key : agentIndex,
                        type: set.chart
                      });
                    }

                  }else{
                    this.chart.data.datasets.push({
                      label: set.display,
                      data: Object.values(data),
                      borderColor: set.color,
                      backgroundColor: this.transparentize(set.color,0.5),
                      stack: set.key,
                      type: set.chart
                    });
                  }
                  this.chart.update()
                })
          }
        }
      }

    },
    /* Internal methods */
    i18n (key) {
      return i18n.t(key)
    },
    localized_time (date) {
      return moment(date).format('LT')
    },
    moment (date) {
      return moment(date)
    },
  },
  mounted () {
    moment.locale(this.locale);
    if(this.locale === 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }

    const canvas = this.$refs.chart;
    this.chart = new Chart(canvas, {
      type: 'bar',
      data: {
        labels: this.getWeekNumbers(),
        datasets: []
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: ''
          }
        },
        scales: {
          y: {
            beginAtZero: true,
          }
        }
      }
    });
    // fetch('', {
    //   method: 'GET',
    //   headers: {}
    // }).then(response => {
    //   return  response.json()})
    //     .then((data) => {
    //     })
  }
}
//

const theme = {
  month: {
    dayName: {
      borderLeft: 'none',
      backgroundColor: 'inherit',
    },
  },
}
</script>