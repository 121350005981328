<template>
  <div class="bg-white rounded p-4 mb-3">
    <span class="h4">{{ this.i18n('scheduling.service_requests.order_plan_header.title') }}</span>
    <span class="float-end">{{ this.filter_pending(this.tasks, false).length }}/{{ this.tasks.length }}</span><br />
    <small>{{ this.i18n('scheduling.service_requests.order_plan_header.subtitle') }}</small>
  </div>

  <div v-if="this.tasks.length > 50" class="bg-white rounded p-3 mb-3">
    <div class="input-group ">
      <span class="input-group-text"><i class="bi bi-search"></i></span>
      <input @keyup="keydownSearch" type="text" class="form-control"
        :placeholder="this.i18n('scheduling.service_requests.order_plan_header.search')">
    </div>
  </div>
  <div class="mb-3">
    <location_card_planned :locale="this.locale" :key="this.filter + '_' + location[0]['id']"
      v-for="location of this.group_by_location(this.filter_pending(this.tasks, false))" :task="location" />
    <location_card_component v-for="location of concepts" :concept="location" :locale="this.locale" />
  </div>
  <button v-if="this.filter_pending(this.tasks, true).length > 0 && this.concepts.length == 0" @click="addConcept"
    class="btn btn-primary float-end">{{ this.i18n('scheduling.service_requests.order_plan_footer.button') }}</button>
</template>

<script>
import location_card_component from "@/vue_components/scheduling_manage/location_card_component.vue";
import location_card_planned from "@/vue_components/scheduling_manage/location_card_planned.vue";
import moment from 'moment/min/moment-with-locales';
import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";

const i18n = new I18n(en);

const statuses_pending = ['pending_reschedule', 'pending']
export default {
  name: "index_manager",
  props: {
    order_id: String,
    locale: String,
  },
  components: {
    location_card_component, location_card_planned
  },
  // Properties returned from data() become reactive state
  // and will be exposed on `this`.
  data() {
    if (this.locale === 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }
    return {
      tasks: [],
      concepts: [],
      filter: "",
    }
  },

  // Methods are functions that mutate state and trigger updates.
  // They can be bound as event listeners in templates.
  methods: {
    i18n(key) {
      return i18n.t(key)
    },
    keydownSearch(e) {
      this.filter = e.target.value
    },
    addConcept() {
      this.concepts.push({ tasks: this.filter_pending(this.tasks, true) })
    },
    filter_pending(tasks, reverse = false, statuses = statuses_pending) {
      let filterv = this.filter;
      return tasks.filter(function (rv) {

        if (rv['parameters_json'] == null) {
          rv['parameters_json'] = {}
        }

        let passfilter = (Object.values(rv['parameters_json']).some((element) => element != null && element.toLowerCase().includes(filterv.toLowerCase())))

        return statuses.includes(rv['status']) === reverse && (passfilter || filterv == "")
      }, {});
    },
    group_by_location(task_array) {

      let object_grouped = task_array.reduce(function (rv, x) {
        let tStr = x['contact_person_postal_code'] + '_' + x['contact_person_house_number'] + "_" + moment(x['starts_at']).format("DD-MM-YYYY");
        (rv[tStr] = rv[tStr] || []).push(x);
        return rv;
      }, {});
      let sorted_by_updated = [];
      for (const date_loc in object_grouped) {
        sorted_by_updated.push(object_grouped[date_loc]);
      }
      sorted_by_updated = sorted_by_updated.sort(function (a, b) {
        return new Date(b[0].updated_at) - new Date(a[0].updated_at);
      }).reverse();
      return sorted_by_updated;
    },
    fetch() {
      fetch('tasks', {
        method: 'GET',
        headers: {}
      })
        .then((response) => { return response.json() })
        .then((data) => {
          this.tasks = [];
          for (const task of data) {
            this.tasks.push(
              task
            )
          }
          this.concepts = []
          this.$forceUpdate();
        });
    },
  },
  // Lifecycle hooks are called at different stages
  // of a component's lifecycle.
  // This function will be called when the component is mounted.
  mounted() {
    moment.locale(this.locale);
    this.fetch();
  },

}
</script>

<style scoped></style>