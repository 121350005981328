<template>
  <div ref="container" class="d-flex flex-column">
    <div class="h-100" v-if="false && unplannedsource"> <!-- in development/placeholder-->
      <UnplannedEvents :eventsource="unplannedsource" />
    </div>
    <div class="task-search p-3" v-if="showSearch">
      <div class="d-flex justify-content-end flex-row gap-2">
        <button class='btn btn-secondary' @click="clearSearch">{{ i18n('clear') }}</button>
        <div><input type="text" class="form-control" placeholder="Search for task content" v-model="search" /></div>
      </div>
    </div>
    <div ref="scheduler" class="flex-grow-1 h-100">
      <FullCalendar :options="calendarOptions" ref="calendar"></FullCalendar>
      <SchedulerCreateTask :open="openCreateModal" :error="createError" :users="resources"
        :available_task_types="taskTypes" :current-user="currentUser" :current-company="currentCompany"
        @errorfix="removeCreateError" @close="closeCreateModal" @save="modalCreateTask" ref="createTaskModal" />
      <SchedulerModal :event="modalEvent" :users="resources" :scheduling-base-url="schedulingBaseUrl"
        :scheduling-service-request-base-url="schedulingServiceRequestBaseUrl" @destroy="deleteEvent"
        @close="closeEventModal" @save="eventModalSave" @confirm="confirmEvent"
        @confirmationRequest="confirmationRequest" ref="modal" />
      <ResourceModal :date="currentDate" :resource="modalResource" @close="closeResourceModal" @save="resourceModalSave"
        ref="resourceModal"></ResourceModal>
    </div>
    <vue-simple-context-menu element-id="schedulerContextMenu" :options="contextMenuOptions" ref="vueSimpleContextMenu"
      @option-clicked="contextMenuOptionClicked" />
  </div>
</template>

<style type="text/css" lang="scss">
@import './../../assets/stylesheets/default_theme.scss';

:root {
  --fc-now-indicator-color: --bs-dark;
  --fc-event-text-color: --bs-dark;
  --scheduler-avail-bg: rgba(189, 59, 59, 0.69);

  --scheduler-pending-bg: rgb(212, 212, 212);
  --scheduler-customer-scheduled-bg: rgb(209, 233, 255);
  --scheduler-pending-reschedule-bg: rgb(255, 246, 193);
  --scheduler-confirmed-bg: rgb(255, 255, 255);
  --scheduler-changed-after-confirm-bg: rgb(243, 193, 255);
  --scheduler-in-progress-bg: rgb(218, 255, 196);
  --scheduler-completed-bg: rgb(116, 255, 61);
  --scheduler-cancelled-bg: rgb(255, 200, 83);
  --scheduler-failed-bg: rgb(255, 81, 81);
}

.fc-header-toolbar {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: $white;
  flex-wrap: wrap;
  gap: 5px;

  &.fc-toolbar {
    margin-bottom: 0px !important;
  }
}

.fc-toolbar-chunk:first-child {
  min-width: 310px;
  display: flex;
  justify-content: space-between;
}

th.fc-resource,
.fc-col-header-cell {
  background-color: $white;
  padding: 5px !important;

  &.fc-timegrid-col {
    // background-color: transparent;
    border-top: 1px solid $gray-400 !important;
  }
}

// Hide the last .fc-toolbar-chunk in .fc-header-toolbar in mobile view
@media (max-width: 767px) {
  .fc-toolbar-chunk:last-child {
    display: none;
  }
}


.fc-timegrid-body {
  margin-top: 15px;
  padding-top: 1px;

  .fc-timegrid-slots {
    >table {
      border-top-style: inherit !important;
    }

    td {
      border: 0px !important;
    }

    tr:has(td > .fc-timegrid-slot-label-frame)>td {
      border-top: 1px solid $gray-400 !important;

      &.fc-timegrid-slot-label {
        border-top: 0px !important;

        .fc-timegrid-slot-label-frame {
          margin-top: calc(var(--bs-body-font-size) * -1.5);
        }

      }
    }
  }

  .fc-timegrid-cols {
    .fc-timegrid-event-harness {
      -webkit-box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .15);
      box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .15);
      overflow: hidden;
      border-radius: 5px;

      :not(.fc-unselectable) &:hover {
        overflow: visible;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: -5px;
          right: -8px;
          bottom: 0;
          background-color: rgba(0, 0, 0, .15);
          // border-radius: 5px;
          border-top: 1px solid rgba(0, 0, 0, .5);
          border-bottom: 1px solid rgba(0, 0, 0, .5);
        }
      }

      &.fc-event-mirror,
      &.fc-event-mirror:hover {
        overflow: hidden !important;
      }
    }

    a.fc-v-event,
    a.fc-event {
      // border: 0;
      background-color: var(--bs-white);
      color: var(--bs-dark);
      border: 0px;

      .fc-event-main {
        padding: 0px;

        .fc-event-main-frame {
          padding: 3px;
        }
      }
    }
  }
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;

  h2.fc-toolbar-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 1em;
    font-weight: bold;
  }


  &:nth-child(1) {
    padding: 5px;
    background-color: $primary-light;

    button.btn {
      // margin: 0 0.5rem;
      padding: 0;
      color: $primary;
      background-color: transparent;
      border: none;
    }
  }

  button.btn {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &:nth-child(2) {
    button.btn {
      border-radius: 0;
      background-color: $white;
      color: $dark;
      margin-left: 0px;
    }

    button.btn.active {
      background-color: $primary-light;
      color: $primary;
    }

    button.btn:first-child {
      // margin: 0 0.5rem;
      border-radius: $border-radius 0 0 $border-radius;
    }

    button.btn:last-child {
      border-radius: 0 $border-radius $border-radius 0;
    }
  }
}

.resource-label-week {
  .fc-col-header-cell-cushion {
    display: block;
    padding: 0;
  }
}

.fc-loadingButton-button {
  padding: 0;
  border: none;
  background-color: transparent;
  color: $primary;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
  display: none;
}
</style>

<style scoped type="text/css">
.fc.fc-media-screen {
  max-height: 100%;
}
</style>

<script lang="js">
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import SchedulerModal from './scheduler_modal.vue'
import SchedulerEvent from './scheduler_event.vue'
import SchedulerCreateTask from './scheduler_create_task.vue'
import ResourceModal from './resource_modal.vue'
import UnplannedEvents from './unplanned_events.vue'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import VueSimpleContextMenu from 'vue-simple-context-menu';
import { createApp } from 'vue'
import moment from 'moment'
import { createEvents as createIcsEvents } from 'ics'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { I18n } from "i18n-js";

import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';

const i18n = new I18n(en);

import en from "@locales/en.json";
import nl from "@locales/nl.json";

if (window.locale == 'nl') {
  i18n.store(nl);
  i18n.locale = 'nl';
}

export default {
  components: {
    SchedulerModal,
    SchedulerEvent,
    ResourceModal,
    UnplannedEvents,
    FullCalendar,
    SchedulerCreateTask,
    VueSimpleContextMenu
  },
  props: {
    eventsource: {
      type: String,
      required: true
    },
    usersource: {
      type: String,
      required: true
    },
    unplannedsource: {
      type: String,
      required: false
    },
    tasktypesource: {
      type: String,
      required: true
    },
    ordercreateurl: {
      type: String,
      required: true
    },
    dayStart: {
      type: String,
      required: false,
      default: '05:00'
    },
    dayEnd: {
      type: String,
      required: false,
      default: '21:00'
    },
    initevent: {
      type: String,
      required: false,
      default: null
    },
    'scheduling-base-url': {
      type: String,
      required: true
    },
    absencecreateurl: {
      type: String,
      required: true
    },
    initialuser: {
      type: String,
      required: false,
    },
    schedulingServiceRequestBaseUrl: {
      type: String,
      required: false
    },
    currentUserJson: {
      type: String,
      required: false
    },
    currentCompanyJson: {
      type: String,
      required: false
    },
  },
  data() {
    let initialEvent = this.initevent ? JSON.parse(this.initevent) : null
    return {
      locale: window.locale,
      modalEvent: null,
      openCreateModal: false,
      modalResource: null,
      showList: false,
      selectionInfo: null,
      resourceData: [],
      resourceSearch: this.initialuser,
      contextMenuOptions: [{ name: this.i18n('absences.actions.new'), class: 'new-absence' }],
      showSearch: initialEvent ? true : false,
      vueEvents: [],
      search: initialEvent?.extendedProps?.search_id?.toString(),
      createError: null,
      taskTypes: [],
      calendarOptions: {
        locale: window.locale,
        plugins: [interactionPlugin, resourceTimeGridPlugin, bootstrap5Plugin, resourceDayGridPlugin, dayGridPlugin, listPlugin, resourceTimelinePlugin],
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        themeSystem: 'bootstrap5',
        initialView: 'resourceTimeGridDay',
        // timeZone: "UTC",
        // timeZone: window.time_zone,
        initialDate: initialEvent?.start,
        filterResourcesWithEvents: true,
        views: {
          resourceTimeGridDay: {
            titleFormat: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
            eventMaxStack: 2,
            buttonText: this.i18n('scheduler.day'),
          },
          resourceGridWeek: {
            type: 'resourceTimeGrid',
            titleFormat: { year: 'numeric', month: 'long' },
            eventMaxStack: 2,
            duration: { weeks: 1 },
            datesAboveResources: true,
            resourceLabelContent: function (arg) {
              return {
                html: '<span style="font-size: 0.5em;">' + arg.resource.title + '</span>'
              }
            },
            resourceLabelClassNames: 'resource-label-week',
            buttonText: this.i18n('scheduler.week'),
          },
          dayGridMonth: {
            eventClassNames: function (arg) {
              if (arg.event.extendedProps.type == 'trip') {
                return ['trip']
              }
            }
          },
          resourceMonth: {
            type: 'resourceDayGridMonth',
            eventMaxStack: 1,
            buttonText: this.i18n('scheduler.month'),
            titleFormat: { year: 'numeric', month: 'long' },
            duration: { months: 1 },
            dayHeaderFormat: { weekday: 'short' },
            resourceLabelContent: function (arg) {
              return {
                html: '<span style="overflow: hidden;">' + arg.resource.title + '</span>'
              }
            },
            eventClassNames: function (arg) {
              if (arg.event.extendedProps.type == 'trip') {
                return ['trip']
              }
            },
            eventContent: function (arg) {
              if (arg.event.extendedProps.type == 'trip') {
                return null
              }

              return {
                html: '<span style="height: 5px; width: 100%; background-color: ' + arg.event.borderColor + '"></span>'
              }
            }
          }
        },
        customButtons: {
          exportIcsButton: {
            text: 'Export ICS',
            click: () => {
              this.exportToIcs()
            }
          },
          createTaskButton: {
            text: this.i18n('tasks.actions.new'),
            click: () => {
              this.createTask()
            }
          },
          searchField: {
            text: this.i18n('search'),
            click: () => {
              this.showSearch = !this.showSearch
              window.dispatchEvent(new Event('resize'));
            }
          },
          loadingButton: {
            icon: ' spinner-border spinner-border-sm',
            click: () => {
              return false;
            }
          },
        },
        headerToolbar: {
          left: 'prev title next',
          center: 'today resourceTimeGridDay resourceGridWeek resourceMonth',
          right: 'loadingButton searchField createTaskButton'
        },
        loading: this.onLoading,
        select: this.onSelect,
        editable: true,
        droppable: true,
        selectable: true,
        height: '100%',
        weekends: false,
        allDaySlot: false,
        navLinks: true,
        weekNumbers: true,
        displayEventTime: true,
        nowIndicator: true,
        slotDuration: '00:15:00',
        slotMinTime: this.dayStart,
        slotMaxTime: this.dayEnd,
        slotLabelInterval: '01:00:00',
        snapDuration: '00:05:00',
        resources: this.resourceGetter,
        resourceLabelContent: this.resourceContent.bind(this),
        events: this.eventsource,
        eventChange: this.onEventChange,
        eventClick: this.onEventClick,
        eventSourceSuccess: this.onEventSourceSuccess.bind(this),
        eventContent: this.eventContent.bind(this),
        eventsSet: this.onEventsSet,
        eventReceive: this.onEventReceive,
        refetchResourcesOnNavigate: true,
        eventMinHeight: 5,
      }
    }
  },
  watch: {
    search: function () {
      for (let i = 0; i < this.vueEvents.length; i++) {
        this.vueEvents[i].search = this.search
        this.calendarApi.updateSize();
      }
    }
  },
  mounted() {
    this.setFullHeight();
    this.loadTaskTypes();
  },
  beforeDestroy() {
  },
  methods: {
    i18n(key) {
      return i18n.t(key)
    },
    closeEventModal() {
      this.modalEvent = null
    },
    eventModalSave(e) {
      this.modalEvent = null;

      this.updateEvent(e).then((_) => {
        this.calendarApi.refetchEvents();
      });
    },
    clearSearch() {
      this.search = null;
    },
    deleteEvent(e) {
      fetch(this.eventsource.replace('.json', '') + '/' + e.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(e)
      }).then((_) => {
        this.modalEvent = null;
        this.calendarApi.refetchEvents();
      });
    },
    removeCreateError() {
      this.createError = null;
    },
    modalCreateTask(event) {
      return fetch(this.ordercreateurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(event)
      }).then((response) => {
        if (response.ok) {
          this.createError = null;
          this.calendarApi.refetchEvents();
          this.openCreateModal = false
        } else {
          response.json().then((data) => {
            this.createError = data
          })
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
    },
    closeCreateModal() {
      this.openCreateModal = false
    },
    createTask() {
      this.openCreateModal = true
    },
    closeResourceModal() {
      this.modalResource = null;
    },
    resourceModalSave(e) {
      this.modalResource = null;

      this.updateResource(e).then((_) => {
        this.calendarApi.refetchResources();
        this.calendarApi.refetchEvents();
      });
    },
    updateEvent(event) {
      return fetch(this.eventsource.replace('.json', '') + '/' + event.event.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(event)
      }).then(() => {
        this.calendarApi.refetchEvents();
      });
    },
    updateResource(resource) {
      return fetch(this.usersource.replace('.json', '') + '/' + resource.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          user: {
            id: resource.id,
            user_date_start_end_locations_attributes: [
              {
                id: resource.user_date_start_end_location_id,
                date: moment(resource.date).format('YYYY-MM-DD 05:00:00'),
                start_location_postal_code: resource.start_location_postal_code,
                start_location_city: resource.start_location_city,
                start_location_street: resource.start_location_street,
                start_location_house_number: resource.start_location_house_number,
                start_location_country: resource.start_location_country,
                end_location_postal_code: resource.end_location_postal_code,
                end_location_city: resource.end_location_city,
                end_location_street: resource.end_location_street,
                end_location_house_number: resource.end_location_house_number,
                end_location_country: resource.end_location_country,
              }
            ]
          }
        })
      })
    },
    createAbsence(absence) {
      let url = this.absencecreateurl.replace('user_id', absence.absence.user_id);
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(absence)
      }).then((response) => {
        if (response.ok) {
          this.calendarApi.refetchEvents();
          // TODO: Toast success
        } else {
          response.json().then((data) => {
            // TODO: Toast error
          })
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
    },
    setFullHeight() {
      this.rootElement.style.height = (window.innerHeight - this.rootElement.getBoundingClientRect().top) + 'px'
    },
    exportToIcs() {
      let events = this.calendarApi.getEvents();
      let icsEvents = [];

      events.forEach((event) => {
        let start = moment(event.start).toArray().slice(0, 5)
        start[1] = start[1] + 1 // month is 0 based, ics is 1 based

        let end = moment(event.end).toArray().slice(0, 5)
        end[1] = end[1] + 1 // month is 0 based, ics is 1 based

        icsEvents.push({
          title: event.title,
          start: start,
          end: end,
          location: '' + event.extendedProps.contact_person_city + ', ' + event.extendedProps.contact_person_postal_code + ' ' + event.extendedProps.contact_person_street + ' ' + event.extendedProps.contact_person_house_number,
          attendees: event.getResources().map((resource) => {
            return {
              name: resource.title,
              email: resource.extendedProps.email
            }
          })
        });
      });

      createIcsEvents(icsEvents, (error, value) => {
        if (error) {
          console.error(error);
          return;
        }

        let blob = new Blob([value], { type: "text/calendar;charset=utf-8" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "calendar.ics";
        link.click();
      });
    },
    eventContent(arg) {
      let event = arg.event;
      let div = document.createElement('div');
      let search = this.search
      let app = createApp({
        components: {
          SchedulerEvent
        },
        template: '<scheduler-event :event="event" :search="search" />',
        data() {
          return {
            search: search,
            event: event,
          }
        },
        computed: {
        }
      }).mount(div)

      this.vueEvents.push(app)

      return { domNodes: [div] }
    },
    resourceGetter(fetchInfo, successCallback, failureCallback) {
      this.loadUsers(fetchInfo, () => { }, failureCallback).then((users) => {
        this.resourceData = users;
        successCallback(this.filteredResources);
      }).finally(() => {
        this.addUserSearchToHeader();
      });
    },
    resourceContent(arg) {
      let resource = arg.resource;
      let div = document.createElement('div');

      let resourceNamediv = document.createElement('div');
      resourceNamediv.innerText = resource.title;
      div.appendChild(resourceNamediv);

      let startAddressDiv = document.createElement('div');
      let endAddressDiv = document.createElement('div');

      if (resource.extendedProps.agent_availabilities) {
        let startAvailability = resource.extendedProps.agent_availabilities.filter((availability) => {
          return availability.day_of_week == moment(arg.date).locale('en').format('dddd').toLocaleLowerCase();
        }).sort((a, b) => {
          return a.start_time - b.start_time;
        })[0]; // Get first availability for this day

        if (startAvailability && startAvailability.postal_code && startAvailability.city) {
          let startAddress = startAvailability.postal_code + ' ' + startAvailability.city;
          startAddressDiv.innerText = 'Start: ' + startAddress;
        } else if (resource.extendedProps.postal_code && resource.extendedProps.city) {
          let startAddress = resource.extendedProps.postal_code + ' ' + resource.extendedProps.city;
          startAddressDiv.innerText = 'Start: ' + startAddress;
        }

        let endAvailability = resource.extendedProps.agent_availabilities.filter((availability) => {
          return availability.day_of_week == moment(arg.date).locale('en').format('dddd').toLocaleLowerCase();
        }).sort((a, b) => {
          return b.end_time - a.end_time;
        })[0]; // Get last availability for this day

        if (endAvailability && endAvailability.end_postal_code && endAvailability.end_city) {
          let endAddress = endAvailability.end_postal_code + ' ' + endAvailability.end_city;
          endAddressDiv.innerText = 'End: ' + endAddress;
        } else if (resource.extendedProps.postal_code && resource.extendedProps.city) {
          let endAddress = resource.extendedProps.postal_code + ' ' + resource.extendedProps.city;
          endAddressDiv.innerText = 'End: ' + endAddress;
        }
      }

      if (resource.extendedProps.user_date_start_end_locations?.length > 0 && resource.extendedProps.user_date_start_end_locations[0]) {
        let startAvailability = resource.extendedProps.user_date_start_end_locations[0]

        if (startAvailability && startAvailability.start_location_postal_code && startAvailability.start_location_city) {
          let startAddress = startAvailability.start_location_postal_code + ' ' + startAvailability.start_location_city;
          startAddressDiv.innerText = 'Start: ' + startAddress;
        }

        let endAvailability = resource.extendedProps.user_date_start_end_locations[0]

        if (endAvailability && endAvailability.end_location_postal_code && endAvailability.end_location_city) {
          let endAddress = endAvailability.end_location_postal_code + ' ' + endAvailability.end_location_city;
          endAddressDiv.innerText = 'End: ' + endAddress;
        }
      }

      startAddressDiv.classList.add('text-muted');
      startAddressDiv.style.fontSize = '0.8em';

      endAddressDiv.classList.add('text-muted');
      endAddressDiv.style.fontSize = '0.8em';

      div.appendChild(startAddressDiv);
      div.appendChild(endAddressDiv);


      div.addEventListener('click', () => this.onResourceClick(resource));

      return { domNodes: [div] }
    },
    onResourceClick(resource) {
      this.modalResource = resource;
    },
    loadUsers(fetchInfo, successCallback, failureCallback) {
      return fetch(this.usersource + '?' + new URLSearchParams({
        start: fetchInfo.startStr,
        end: fetchInfo.endStr
      }))
        .then(response => response.json())
        .then(data => {
          let resources = [];
          for (let i = 0; i < data.length; i++) {
            let resource = {
              id: data[i].id,
              title: data[i].full_name,
              extendedProps: {
                email: data[i].email,
                agent_availabilities: data[i].agent_availabilities,
                user_date_start_end_locations: data[i].user_date_start_end_locations,
                postal_code: data[i].postal_code,
                main_role: data[i].main_role.identifier,
                city: data[i].city,
                house_number: data[i].house_number,
                main_role: data[i].main_role.identifier,
                street: data[i].street,
                country: data[i].country,
              }
            }

            resources.push(resource);
          }

          resources.push({
            id: null,
            title: 'Unassigned',
            width: '10%',
          })
          this.resourceData = resources;
          return resources;
        })
        .then(resources => {
          successCallback(resources);
          return resources;
        })
        .catch(error => {
          console.error(error);
          failureCallback(error);
        })
        .finally(resources => {
          return resources
        })
    },
    loadTaskTypes() {
      fetch(this.tasktypesource)
        .then(response => response.json())
        .then(data => {
          let taskTypes = [];
          for (let i = 0; i < data.length; i++) {
            let taskType = data[i]

            taskTypes.push(taskType);
          }

          this.taskTypes = taskTypes;
        })
    },
    addUserSearchToHeader() {
      let resourceSearchWrapper = document.getElementsByClassName('resource-search')[0];
      if (resourceSearchWrapper) {
        return
      }

      let resourceSelect = document.createElement('select');
      resourceSelect.classList.add('form-select');
      resourceSelect.classList.add('form-control-sm');
      resourceSelect.classList.add('mr-2');

      let option = document.createElement('option');
      option.value = '';
      option.innerText = this.i18n('scheduler.all_users');
      resourceSelect.appendChild(option);

      for (let i = 0; i < this.resourceData.length; i++) {
        let resource = this.resourceData[i];

        let option = document.createElement('option');
        option.value = resource.title;
        option.innerText = resource.title;
        resourceSelect.appendChild(option);
      }

      resourceSearchWrapper = document.createElement('div');
      resourceSearchWrapper.classList.add('fc-toolbar-chunk');
      resourceSearchWrapper.classList.add('resource-search');

      let headerToolbar = document.getElementsByClassName('fc-header-toolbar')[0];
      headerToolbar.children[1].after(resourceSearchWrapper);
      resourceSearchWrapper.append(resourceSelect);

      if (this.initialuser && this.initialuser != '') {
        resourceSelect.value = this.initialuser;
        this.resourceSearch = this.initialuser;
      }

      resourceSelect.addEventListener('change', (e) => {
        this.resourceSearch = e.target.value;
        this.calendarApi.refetchResources();
      })

      // Hide the select if the current_users main_role does not have admin in the identifier string
      if (!this.currentUser.main_role.identifier.includes('admin')) {
        resourceSearchWrapper.style.display = 'none';
      }

    },
    onEventClick(e) {
      if (e.event.extendedProps.type === 'task') {
        this.modalEvent = e.event;
      }

      if (e.event.url) {
        e.jsEvent.preventDefault();

        let url = e.event.url;

        // if its a google maps but we are on apple, open apple maps
        let is_iphone = navigator.userAgent.toLowerCase().includes('iphone');

        if (e.event.url.includes('google.com/maps') && is_iphone) {
          url = "http://maps.apple.com/?q=" + e.event.extendedProps.contact_person_postal_code + ' ' + e.event.extendedProps.contact_person_house_number + ' ' + e.event.extendedProps.contact_person_city;
        }
        window.open(url, '_blank');
      }
    },
    onEventsSet(e) {
    },
    onEventReceive(e) { // when a new event is dropped
      // in dev progress
      if (moment(e.event.startStr).format('x') == moment(e.event.start).startOf('x')) {
        e.event.setStart(moment(e.event.start).add(9, 'hours').toDate());
        e.event.setEnd(moment(e.event.start).add(parseInt(e.event.extendedProps.default_duration_seconds), 'seconds').toDate());
      }
    },
    confirmEvent(e) {
      this.modalEvent = null;
      e.confirmed = true;

      this.updateEvent(e)
    },
    confirmationRequest(e) {
      this.modalEvent = null;
      e.confirmationRequest = true;
      this.updateEvent(e)
    },
    onEventChange(e) {
      let event = {
        event: {
          id: e.event.id,
          starts_at: e.event.start,
          ends_at: e.event.end,
          user_id: e.event.user_id || e.event?.getResources()[0].id,
        }
      };

      return this.updateEvent(event);
    },
    onEventSourceSuccess(content, xhr) {
      this.vueEvents = [];
      return content.eventArray;
    },
    onLoading(loading) {
      if (loading) {
        document.getElementsByClassName('fc-loadingButton-button').forEach((element) => {
          element.style.display = 'block';
        });
      } else {
        document.getElementsByClassName('fc-loadingButton-button').forEach((element) => {
          element.style.display = 'none';
        });
      }
    },
    onSelect(selectionInfo) {
      this.selectionInfo = selectionInfo
      document.getElementsByClassName('fc-highlight').forEach((element) => {
        element.addEventListener('contextmenu', (e) => {
          e.preventDefault();
          this.$refs.vueSimpleContextMenu.showMenu(e)
        })
      });
    },
    contextMenuOptionClicked(option) {
      if (option.option.class === 'new-absence') {
        let reason = prompt(this.i18n('activerecord.attributes.absence.reason'))
        if (reason) {
          let absence = {
            absence: {
              starts_at: this.selectionInfo.start,
              ends_at: this.selectionInfo.end,
              user_id: this.selectionInfo.resource.id,
              reason: reason
            }
          };

          this.createAbsence(absence);
        }
      }
    },
  },
  computed: {
    currentDate() {
      if (this.modalResource && this.$refs.calendar) {
        return this.$refs.calendar.getApi().getDate()
      }
    },
    rootElement() {
      return this.$refs.container
    },
    calendarApi() {
      return this.$refs.calendar?.getApi()
    },
    filteredResources() {
      if (this.resourceSearch !== null && this.resourceSearch !== '') {
        return this.resourceData.filter(resource => resource.title.toLowerCase().includes(this.resourceSearch.toLowerCase()))
      } else {
        return this.resourceData
      }
    },
    resources() {
      if (this.modalEvent || this.openCreateModal || this.$refs.calendar?.getApi()) {
        return this.$refs.calendar.getApi().getResources()
      }
    },
    currentUser() {
      if (this.currentUserJson) {
        return JSON.parse(this.currentUserJson);
      }
    },
    currentCompany() {
      if (this.currentCompanyJson) {
        return JSON.parse(this.currentCompanyJson);
      }
    },
  }
}
</script>