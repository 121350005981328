<template>
  <div class="h-100">
    <div>
      <div class="bg-white p-2">
        <h5 class="text-center p-2">
          {{ i18n('calendars.unplanned_tasks') }}
        </h5>
      </div>
      <div class="p-2 d-flex flex-column gap-2" ref="unplannedList">
        <div v-for="event in events" class="unplanned-event bg-white p-2 rounded draggableEvent" ref="draggables" :data-event="JSON.stringify(event)">
          <div class="d-flex justify-content-between gap-3">
            <span>{{event.title}}</span><small>Unplanned</small>
          </div>
          <div>
            <small>{{event.extendedProps.contact_person_street}} {{event.extendedProps.contact_person_house_number}}, {{event.extendedProps.contact_person_city}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css" lang="scss">
.unplanned-event {
  cursor: pointer;

  &:hover {
    background-color: var(--bs-dark) !important;
    color: var(--bs-white) !important;
  }
}
</style>

<script>
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import moment from 'moment'
import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";

const i18n = new I18n(en);

export default {
  props: ['eventsource'],
  watch: {
    eventsource () {
      this.getEvents()
    },
    events () {
    }
  },
  data () {
    return {
      locale: window.locale,
      events: []
    }
  },
  methods: {
    i18n (key) {
      return i18n.t(key)
    },
    getEvents () {
      fetch(this.eventsource)
        .then(response => response.json())
        .then(data => {
          this.events = data
        })
    }
  },
  computed: {
    eventType () {
      return this.event?.type
    },
    title () {
      return this.event?.title
    }
  },
  mounted () {
    moment.locale(this.locale);

    if(this.locale == 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }

    this.getEvents()
  },
  updated () {

    if(this.$refs.draggables) {
      for(let i = 0; i < this.$refs.draggables.length; i++) {
        new Draggable(this.$refs.draggables[i], {
          eventData: function(eventEl) {
            return JSON.parse(eventEl.dataset.event)
          }
        })
      }
    }


  }
}

</script>