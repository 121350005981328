<template>
  <div  class="bg-white rounded p-4">
    <div class="w-100" >
      <location_card_plan_step1 v-if="this.current_step == 0" :concept="this.concept"/>
      <location_card_plan_step2 :locale="this.locale" v-if="this.current_step == 1" :concept="this.concept"/>
      <location_card_plan_step3 :locale="this.locale" v-if="this.current_step == 2" :concept="this.concept" :slots="this.slotData"/>
    </div>
  </div>
</template>

<script>
import location_card_plan_step1 from "@/vue_components/scheduling_manage/location_card_plan_step1.vue";
import location_card_plan_step2 from "@/vue_components/scheduling_manage/location_card_plan_step2.vue";
import location_card_plan_step3 from "@/vue_components/scheduling_manage/location_card_plan_step3.vue";
export default {
  name: "location_card_component",
  props: {
    concept: Object,
    locale: String
  },
  components: {
    location_card_plan_step1,location_card_plan_step2,location_card_plan_step3
  },
  methods: {
    setStep(step){
      // window.history.replaceState({step: this.current_step}, null, "");
      window.history.pushState({step: this.current_step}, null, "");
      this.current_step = step;
    },
    setSlot(slot){
      this.slotData = slot;
    },
    preventBackButton(ev){
      if(this.current_step == 0){
        this.$parent.concept = [];
        return
      }
      this.setStep(this.current_step-1);
    }
  },
  data() {
    return {
      current_step: 0,
      slotData: null
    }
  },
  mounted() {
    window.history.pushState({step: 0}, null, "");
    window.onpopstate =this.preventBackButton;
  },
  beforeDestroy () {
    window.onpopstate = null;
  }
}
</script>

<style scoped>
  div.tab-header{
    cursor: pointer;
  }
</style>