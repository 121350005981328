<template>
  <div class="col bg-white rounded p-3 flex-grow-1">
    <h5>{{this.title}}</h5>
    <canvas style="width: 100%" ref="chart"></canvas>
    <div class="legend" :class="this.legendCollapsed ? 'collapsed' : ''">
      <div v-for="entry in this.legend.sort((a,b) => b.value-a.value)"  class="transitioner d-flex justify-content-between p-1 border-bottom">
        <div style="width: 30px;" :style="{backgroundColor: entry.color}"></div>
        <div class="flex-column flex-grow-1 m-1">{{entry.label}}</div>
        <div class="flex-column">{{entry.value}}</div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <a v-if="this.legend.length > 5" href="#" @click="(e)=>{e.preventDefault();this.legendCollapsed = !this.legendCollapsed}"><i>Click to show {{this.legendCollapsed? "more" : "less"}}</i></a>
    </div>


  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import moment from 'moment/min/moment-with-locales';
import { Colors } from 'chart.js';

Chart.register(Colors);

export default {
  name: "kpiCard",
  props: {
    type: String,
    title: String,
    frames: Object
  },
  watch: {
    frames: {
      handler(val){
        this.request();
      }
    }
  },
  methods: {
    request(){
      if(!this.chart) return;
      let values = {};
      for (const frame of Object.values(this.frames)) {
        for (const subtypeKey of Object.keys(frame[this.type])) {
          if(values.hasOwnProperty(subtypeKey)){
            values[subtypeKey] += frame[this.type][subtypeKey];
          }else{
            values[subtypeKey] = frame[this.type][subtypeKey];
          }
        }
      }
      this.chart.data.labels = Object.keys(values);
      if(this.chart.data.datasets.length > 0){
        this.chart.data.datasets[0].data = Object.values(values);
      }else{
        this.chart.data.datasets.push({
          label: "Tasks",
          data: Object.values(values),
          backgroundColor: ['#FF6633', '#FFB399', '#4D80CC',
            '#B33300', '#6680B3', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF']
        });
      }
      this.chart.update()
    }
  },
  data(){

    return {
      legend: [],
      legendCollapsed: true,

    };
  },
  mounted() {
    const canvas = this.$refs.chart;
    this.chart = new Chart(canvas, {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        animation: {
          duration: 1000,
        },
        responsive: true,
        plugins: {
          colors: {
            forceOverride: true
          },
          legend: {
            position: 'bottom',
            align: "left",
            labels: {
              generateLabels: (chart) => {
                const datasets = chart.data.datasets;
                if(datasets.length === 0) return ;
                this.legend = [];
                datasets[0].data.forEach((data, i) => {
                  if(data !== 0){
                    this.legend.push({label: chart.data.labels[i],
                      value: data,
                      color: datasets[0].backgroundColor[i]});
                  }
                })
              }
            }
          },
        },
      }
    });
    this.request();
  }
}
</script>

<style scoped>
.legend{
  overflow: hidden;
  max-height: 500px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.legend.collapsed{
  max-height: 200px;
}
</style>