<template>
  <div class="row">
    <div class="col bg-white rounded p-3 mb-3 mt-3">
      <div class="d-flex flex-row justify-content-between">
        <div >
          <h3>Dashboard</h3>
        </div>
        <div>
          <div class="input-group ">
            <dateRanger  :callback="adjustCallback" :frames="frames_to_month(this.dataFrames)"></dateRanger>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row gap-3 mb-3">
    <kpi-card :frames="frames_to_month(this.selectedFrames)"  :title="i18n('tasks_per_type')" type="tasks_per_type" class=""/>
    <kpi-card :frames="frames_to_month(this.selectedFrames)"  :title="i18n('tasks_per_status')" type="tasks_per_status" class=""/>
    <kpi-card :frames="frames_to_month(this.selectedFrames)"  :title="i18n('tasks_per_agent')" type="tasks_per_agent" class=""/>
  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';

import kpiCard from "@/vue_components/dashboard/KPI_card.vue";
import dateRanger from "@/vue_components/dashboard/KPI_dateranger.vue";

import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";

const i18n = new I18n(en);
i18n.store(nl);

export default {
  name: "KPI_maindashboard",
  components: {
    kpiCard,dateRanger
  },
  mounted () {
    moment.locale(window.locale);
    console.log(window.locale);
    if(window.locale == 'nl') {
      
      i18n.locale = 'nl';
    } else {
      i18n.locale = 'en';
    }
  },
  data(){
    return {
      dataFrames: {},
      selectedFrames: [],
      range: {
        start: new Date(Date.now() - 1000*3600*24*14),
        end: new Date(Date.now() + 1000*3600*24*14)
      },
    }
  },
  methods: {
    i18n (key) {
      return i18n.t(key)
    },
    frames_to_month(frames){
      let frames_joined = {};

      for (const frame of Object.values(frames)) {
        let key = frame.date.getFullYear()+"_" + frame.date.getMonth();
        let frameMonthObj = {
          task_count: 0,
          task_time_per_agent: {},
          tasks_per_agent: {},
          tasks_per_status: {},
          tasks_per_type: {},
          availability_per_agent: {},
          travel_mileage_per_agent: {},
          travel_time_per_agent: {}
        };
        if(!frames_joined.hasOwnProperty(key))
          frames_joined[key] = frameMonthObj;

        for (const frameMonthObjKey of Object.keys(frameMonthObj)) {
           if(typeof frameMonthObj[frameMonthObjKey] === 'object'){
             if(!frame.hasOwnProperty(frameMonthObjKey)) continue;

             for (const subtypeKey of Object.keys(frame[frameMonthObjKey])) {
               if(frames_joined[key][frameMonthObjKey].hasOwnProperty(subtypeKey)){
                 frames_joined[key][frameMonthObjKey][subtypeKey] += frame[frameMonthObjKey][subtypeKey];
               }else{
                 frames_joined[key][frameMonthObjKey][subtypeKey] = frame[frameMonthObjKey][subtypeKey];
               }
             }
           }else{
             frames_joined[key][frameMonthObjKey] += frame[frameMonthObjKey];
           }
        }
      }
      return frames_joined;
    },
    smart_fetch(){

      if(this.dataFrames.length == 0){
        return this.fetch_frames(moment(this.range.start),moment(this.range.end));
      }
      let firstDateInFrames = Date.now();
      let lastDateInFrames = Date.now();
      for (const dataFrame of Object.values(this.dataFrames)) {
        if(dataFrame.date < firstDateInFrames){
          firstDateInFrames = dataFrame.date;
        }
        if(dataFrame.date > lastDateInFrames){
          lastDateInFrames = dataFrame.date;
        }
      }
      return new Promise((resolve, reject) =>{
        let count = 0;
        if(this.range.start < firstDateInFrames){
          count++;
          this.fetch_frames(this.range.start,firstDateInFrames).then(()=>{count--; if(count === 0) resolve();});
        }
        if(this.range.end > lastDateInFrames){
          count++;
          this.fetch_frames(lastDateInFrames,this.range.end).then(()=>{count--; if(count === 0) resolve();});
        }
        if(count === 0) resolve();
      });

    },
    select_frames(){
      this.selectedFrames = [];
      for (const frameDate in this.dataFrames) {
        let frame = this.dataFrames[frameDate];
        if(frame.date > this.range.start && frame.date < this.range.end){
          this.selectedFrames.push(frame);
        }
      }
    },
    fetch_frames(start,end){
      return new Promise((resolve, reject) =>{

        let dateStart = moment(start);
        let dateEnd = moment(end);
        dateStart.milliseconds(-10);
        dateEnd.milliseconds(10);
        fetch("/kpi/get_report_frames?start="+dateStart.format("MM-DD-YYYY")+"&end="+dateEnd.format("MM-DD-YYYY"))
            .then(response => response.json())
            .then(data => {
              for (const report_date in data) {
                data[report_date]['date'] = new Date(report_date);
                this.dataFrames[report_date] = (data[report_date]);
              }
              resolve();
            })
      });

    },
    adjustCallback(start,end){
      this.range.start = start;
      this.range.end = end;
      this.smart_fetch().then(()=>this.select_frames());
    }
  }
}
</script>

<style scoped>

</style>