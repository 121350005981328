import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="settings-users"
export default class extends Controller {
  static targets = ["selectable-user"]

  connect() {
  }
  select(event) {
    this.setSelected(event.currentTarget);
  }
  setSelected(currentTarget) {
    this['selectable-userTargets'].forEach((userTarget) => {
      if (currentTarget == userTarget) {
        userTarget.classList.add('active');
      } else {
        userTarget.classList.remove("active");
      }
    });
  }
}
