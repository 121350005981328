import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="settings-task-types"
export default class extends Controller {
  static targets = [ "selectable-taskType" ]

  connect() {
  }
  select (event) {
    this.setSelected(event.currentTarget);
  }
  setSelected (currentTarget) {
    this['selectable-taskTypeTargets'].forEach((task_typesTarget) => {
      if (currentTarget == task_typesTarget) {
        task_typesTarget.classList.add('active');
      } else {
        task_typesTarget.classList.remove("active");
      }
    });
  }
}
