import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="client-portal-tasks"
export default class extends Controller {
  connect() {
    console.log("Client Portal Tasks Controller Connected")
  }

  disconnect() {
    console.log("Client Portal Tasks Controller Disconnected")
  }

  remove(event) {
    event.preventDefault()

    // Get the reload frame element
    const reloadFrame = document.getElementById(event.target.getAttribute("data-turbo-reload-frame"))

    console.log("things", event.target.getAttribute("data-turbo-reload-frame"))

    console.log("reloadFrame", reloadFrame)

    // find .reload-tasks element in the reload frame
    const reloadTasks = reloadFrame.querySelector(".reload-tasks")


    // Get the turbo-frame element
    const frame = event.target.closest("turbo-frame")
    frame.remove()

    // Reload the frame by clicking the reload button
    reloadTasks.click()
  }

  // submit action
  submit(event) {
    event.preventDefault()
    console.log("Client Portal Tasks Controller Submit")

    // Get the form element, which is a parent somewhere up the DOM tree
    const form = event.target.closest("form")

    // Create a button element to submit the form
    const button = document.createElement("button")
    button.type = "submit"
    button.setAttribute("formaction", event.target.getAttribute("formaction"))
    button.setAttribute("formnovalidate", true)
    button.setAttribute("data-turbo-frame", event.target.closest("turbo-frame").id)
    button.style.display = "none"
    form.appendChild(button)
    button.click()
  }
}