<template>
  <div  class="bg-white rounded p-4 mb-3">
    <div @mousedown="toggle_collapse" class="w-100 tab-header">
      <span>
        {{this.task[0]['contact_person_street']}}  {{this.task[0]['contact_person_house_number']}},  {{this.task[0]['contact_person_postal_code']}} {{this.task[0]['contact_person_city']}}
      </span>

      <span class="float-end">
        <i v-if="this.task[0]['status'] == 'customer_scheduled'" style="font-size: 1.2em;" class="text-warning bi bi-clock-history"></i>
        <i v-else style="font-size: 1.2em;" class="text-success bi bi-check-circle-fill"></i>
      </span><br/>
      <small>{{this.moment(this.task[0]['starts_at']).format('L')}} </small>
    </div>
    <div v-if="!prop_collapsed" class="w-100 mt-2">
      <hr>
      <div ref="card_extended" v-html="this.html"></div>
    </div>
    <div class="modal" ref="modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{this.i18n("tasks.actions.cancel")}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p v-if="this.past_deadline">{{this.i18n("scheduling.service_requests.verify_cancel.disabled")}}</p>
            <p v-else>{{this.i18n("scheduling.service_requests.verify_cancel.warning")}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{this.i18n('close')}}</button>
            <button type="button" @click="cancelVerify" data-bs-dismiss="modal" :class="(this.past_deadline ? 'disabled' : '')" class="btn btn-primary">{{this.i18n("scheduling.service_requests.verify_cancel.verify")}}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment/min/moment-with-locales';
import {nextTick} from "vue";
import nl from "@locales/nl.json";
import en from "@locales/en.json";
import {I18n} from "i18n-js";

const i18n = new I18n(en);

export default {
  name: "location_card_component",
  props: {
    task: Object,
    locale: {
      type: String,
      required: false,
      default: 'en'
    },

  },
  data() {

    moment.locale(this.locale);

    if(this.locale === 'nl') {
      i18n.store(nl);
      i18n.locale = 'nl';
    }

    return {
      prop_collapsed: true,
      html: null,
      past_deadline: false
    }
  },
  methods: {
    toggle_collapse(e) {
      this.prop_collapsed = !this.prop_collapsed;
      e.preventDefault()

    },
    moment(d){
      return moment(d);
    },
    cancelLink(ev){
      ev.preventDefault();
      const id = parseInt(ev.target.dataset.id);
      for (const task of this.task) {
        if(task.id===id){
          const deadline = new Date(task.starts_at).getTime() - (1 * 24 * 60 * 60 * 1000);
          this.past_deadline = (deadline < new Date());
          new bootstrap.Modal(this.$refs.modal, {}).show();
          this.$refs.modal.dataset['taskid'] = id;
          break;
        }
      }
    },
    cancelVerify(ev){
      fetch("cancel_task?taskid="+this.$refs.modal.dataset.taskid, {
        method: 'GET',
        headers: {}
      })
          .then((response) => {
            this.$parent.fetch();
          });
    },
    i18n (key) {
      return i18n.t(key)
    },
  },
  updated(){
    if(this.prop_collapsed) return;
    let task_ids = [];
    for (const task of this.task) task_ids.push(task.id);
    fetch("sub_planned_overview?id="+JSON.stringify(task_ids), {
      method: 'GET',
      headers: {}
    })
        .then((response)=> {return  response.text()})
        .then((data) => {
          this.html = data;
          nextTick(()=> {
            let elements = this.$refs.card_extended.querySelectorAll(".remove_task_link");
            for (const element of elements) {
              element.onclick = this.cancelLink;
            }
            elements = this.$refs.card_extended.querySelectorAll(".converttimestamptotime");
            for (const element of elements) {
              element.innerHTML = new Date(element.getAttribute("data-date")).toLocaleTimeString().split(':').slice(0, 2).join(':')
            }
          })
        });
  },


  mounted() {
    moment.locale(this.locale);

  }
}
</script>

<style scoped>
  div.tab-header{
    cursor: pointer;
  }
</style>