<template>
  <div :class="eventClass">
    <div v-if="event.extendedProps.type === 'task'" class="event-overflow-wrapper" ref="eventContainer">
      <div class="transparent-bg" :style="backgroundColor"></div>
      <div class="fc-event-main-frame"  >
        <div class="fc-event-main">
          <div class="fc-colored-header" :style="eventColor"></div>
          <div class="fc-event-title">
            <div class="d-flex justify-content-start align-items-start gap-2">
              <span class="flex-grow-1 " >{{event.title}}</span>
              <span class="badge bg-dark" style="font-size: 0.7em; height: auto; margin-top: 1px">{{event.extendedProps.status}}</span>
              <span v-if="event.extendedProps.notes.length > 0"><i class="bi bi-stickies"></i></span>
              <span v-if="event.extendedProps.from_suggestion"><i class="bi bi-star-fill icon-gold icon-shadow"></i></span>
            </div>
            <div class="fc-customer">{{event.extendedProps.contact_person_company_name}}</div>
            <div class="fc-location">{{event.extendedProps.contact_person_postal_code}} {{event.extendedProps.contact_person_city}}</div>
            <div class="fc-event-time">{{eventStart}} - {{eventEnd}}</div>
            <div v-for="customtask in event.extendedProps.custom_task_fields" >
              <span v-if="customtask && customtask.show" class="fc-customfield">{{customtask.name}}: {{customtask.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="eventContainer" v-if="event.extendedProps.type === 'trip'" style="overflow: hidden">
      <div class="fc-event-main-frame">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="fw-bold me-1" style="white-space: nowrap;">{{duration}}</div>
          <div class="fw-bold" style="white-space: nowrap;">{{event.extendedProps.km}} km</div>
        </div>
      </div>
    </div>
    <div ref="eventContainer" v-if="event.extendedProps.type === 'absence'" style="overflow: hidden">
      <div class="fc-event-main-frame" style="color: white;">
        <div class="d-flex justify-content-between flex-wrap">
          <div>{{event.title}}</div>
          <div class="me-1" style="white-space: nowrap;">{{duration}}</div>
        </div>
        <div class="fc-event-time">{{eventStart}} - {{eventEnd}}</div>
        <div class="fw-bold">{{event.getResources()[0].title}}</div>
      </div>
    </div>
  </div>

</template>

<style type="text/css" lang="scss">
.event-overflow-wrapper {
  position: relative;
  
  // pointer-events: none;
}

.fc-timegrid-event-harness:hover {
  z-index: 0;
}

.fc-timegrid-event-harness:has(.searched) {
  z-index: 9 !important;
  filter: drop-shadow(0 0 0.5rem var(--bs-primary, #FFF));
}

.fc-timeline-event-harness:has(.searched) {
  z-index: 9 !important;
  filter: drop-shadow(0 0 0.5rem var(--bs-primary, #FFF));
}

.fc-daygrid-event-harness:has(.searched) {
  z-index: 9 !important;
  filter: drop-shadow(0 0 0.5rem var(--bs-primary, #FFF));
}

.fc-daygrid-event-harness {
  .trip {
    display: none;
  }
}

.notSearched {
  opacity: 0.5;
}

.align-border-helper {
  position: absolute;
  top: 0;
  left: -2px;
  border: 1px solid black;
  height: 100%;
  width: 100%;
}

.transparent-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
}

.fc-colored-header {
  height: 5px;
  border-radius: 5px 5px 0 0;
}
</style>

<script>
import moment from 'moment'

export default {
  props: {
    event: {  
      type: Object,
      required: true
    },
    search: {  
      type: String,
      required: false
    },
  },
  watch: {
  },
  data () {
    return {
      eventType: this.event.type
    }
  },
  methods: {
    moment: function (date) {
      return moment(date)
    },
    filterIt(obj, searchKey) {
      let objectString = JSON.stringify(obj).toLowerCase()
      let splitSearch = searchKey.toLowerCase().split(' ')

      let found = true
      for(let i = 0; i < splitSearch.length; i++) {
        if(!objectString.includes(splitSearch[i])) {
          found = false
        }
      }

      return found
    },
  },

  computed: {
    eventStart() {
      return moment(this.event.start).format('HH:mm');
    },
    eventEnd() {
      if (this.event.end) {
        return moment(this.event.end).format('HH:mm');
      } else {
        return moment(this.event.start).add(this.event.extendedProps.default_duration_seconds, 'seconds').format('HH:mm');
      }
    },
    duration () {
      return moment.utc(moment(this.event.end).diff(moment(this.event.start))).format('HH:mm');
    },
    eventClass () {
      if(this.search && this.event.extendedProps.type === 'task') {
        if(this.search) {
          if(this.filterIt(this.event, this.search)) { 
            return 'searched'
          } else {
            return 'notSearched'
          }
        }
      } 
    },
    backgroundColor() {
      return {
        backgroundColor: this.event.backgroundColor,
        opacity: 1
      }
    },
    eventColor() {
      if(this.event && this.event.borderColor) {
        return {
          backgroundColor: this.event.borderColor
        }
      }
    }
  },
}


</script>