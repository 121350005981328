import { Controller } from "@hotwired/stimulus"
import { createApp } from "vue/dist/vue.esm-bundler.js";
import Kpi from "@/vue_components/kpi.vue";
import { createI18n } from 'vue-i18n'

export default class extends Controller {
  static targets = [ "kpi" ]

  connect () {
    this.mount()
  }

  mount () {
    this.kpiTargets.forEach(element => {
      if(!element.__vue_app__) {
        let app = createApp({
          components: {
            kpi: Kpi
          },
          compilerOptions: {
            isCustomElement: tag => { 
              tag.startsWith('turbo')
            }
          }
        })
        app.use(createI18n({}));
        app.mount(element)
      }
    });
  }

}
