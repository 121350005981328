<template>
  <div class="modal modal-lg fade" tabindex="-1" ref="modal">
    <div class="modal-dialog" >
      <div class="modal-content">
        <div class="title-event-color" :style="eventColor"></div>
        <div class="modal-header event-modal-header">
          <h1 class="modal-title fs-5">{{title}}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="open">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-link" @click="quickFill">
              <span>{{ i18n ('activerecord.actions.task.quick_fill') }}</span>
            </button>
          </div>
          <form ref="createModalForm" data-controller="address-lookup">
            <div class="row mb-2">
              <div class="col-3">
                <label class="col-form-label" for="external_order_id">{{i18n('activerecord.attributes.order.external_order_id')}} *</label>
              </div>
              <div class="col-9">
                <input type="text" v-model="external_order_id" name="external_order_id" class="form-control" @change="idChange" required/>
                <div v-if="error && error.detail.external_order_id">
                  <small class="text-danger">{{error.detail.external_order_id.join(', ')}}</small>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3"><label for="contact_person_firstname" class="col-form-label">{{i18n('activerecord.attributes.task.contact_person_firstname')}} *</label></div>
              <div class="col"><input type="text" v-model="contact_person_firstname" name="contact_person_firstname" class="form-control" required/></div>
              <div class="col-3"><label for="contact_person_lastname" class="col-form-label">{{i18n('activerecord.attributes.task.contact_person_lastname')}} *</label></div>
              <div class="col"><input type="text" v-model="contact_person_lastname" name="contact_person_lastname" class="form-control" required/></div>
            </div>
            <div class="row mb-2">
              <div class="col-3"><label for="contact_person_company_name" class="col-form-label">{{i18n('activerecord.attributes.task.contact_person_company_name')}} *</label></div>
              <div class="col"><input type="text" v-model="contact_person_company_name" name="contact_person_company_name" class="form-control" required/></div>
              <div class="col-3"><label for="contact_person_phone_number" class="col-form-label">{{i18n('activerecord.attributes.task.contact_person_phone_number')}} *</label></div>
              <div class="col"><input type="text" v-model="contact_person_phone_number" name="contact_person_phone_number" class="form-control" required/></div>
            </div>
            <div class="row mb-2">
              <div class="col-3"><label for="contact_person_email" class="col-form-label">{{i18n('activerecord.attributes.task.contact_person_email')}} *</label></div>
              <div class="col"><input type="text" v-model="contact_person_email" name="contact_person_email" class="form-control" required/></div>
            </div>
            <hr/>
            <div class="row mb-2">
              <div class="col">
                <label for="newTaskCount" class="col-form-label">{{i18n('activerecord.actions.task.new_task_count')}}</label>
                <input type="number" v-model="newTaskCount" name="newTaskCount" class="form-control"/>
              </div>
              <div class="col">
                <label for="newTaskType" class="col-form-label">{{i18n('activerecord.models.task_type.one')}}</label>
                <select v-model="newTaskType" name="newTaskType" class="form-select">
                  <option v-for="taskType in available_task_types" :value="taskType">{{taskType.name}}</option>
                </select>
              </div>
              <div class="col-2 d-flex align-items-end">
                <a href="javascript:;" class="btn btn-primary align-bottom" @click="createTask">{{i18n('activerecord.actions.task.add')}}</a>
              </div>
            </div>
            <div v-for="task in tasks" class="row mb-2">
              <div class="row mb-2">
                <div class="col-3">
                  <label class="col-form-label">{{i18n('activerecord.models.task_type.one')}} *</label>
                </div>
                <div class="col-9">
                  <select class="form-select" :value="task?.task_type?.id || null" @change="setTaskType(task, $event)" required>
                    <option v-for="tsktype in available_task_types" :value="tsktype.id">{{tsktype.name}}</option>
                  </select>
                </div>
              </div>
              <div data-controller="licenseplate-lookup">
                <div class="row mb-2" v-if="task.task_type && task.task_type.custom_task_fields.length > 0" v-for="custom_task_field in task.task_type.custom_task_fields">
                  <div class="col-3"><label :for="custom_task_field.name" class="col-form-label">{{custom_task_field.name}} {{ custom_task_field.required_before_start ? '*' : ''}}</label></div>
                  <div class="col">
                    <input type="text" v-model="custom_task_field.value" v-bind="licensePlateAttributes(custom_task_field)" class="form-control" :required="custom_task_field.required_before_start" />
                  </div>
                </div>    
              </div>
 
              <div class="row mb-2">
                <div class="col">
                  <a href="javascript:;" class="btn btn-danger" @click="removeTask(task)">{{i18n('activerecord.actions.task.remove')}}</a>
                </div>
              </div>
            </div>
            <hr/>
            <div class="mb-2 row align-items-center flex-row ">
              <div class="col-2"><label for="contact_person_postal_code">{{i18n('activerecord.attributes.task.contact_person_postal_code')}} *</label></div>
              <div class="col">
                <input type="text" 
                       v-model="contact_person_postal_code" 
                       data-address-lookup-target="postalcode" 
                       data-action="blur->address-lookup#lookup" 
                       name="contact_person_postal_code" 
                       class="form-control" required/>
              </div>
              <div class="col"><label for="contact_person_house_number" >{{i18n('activerecord.attributes.task.contact_person_house_number') }} *</label></div>
              <div class="col">
                <input type="text" 
                       v-model="contact_person_house_number" 
                       data-address-lookup-target="housenumber" 
                       data-action="blur->address-lookup#lookup" 
                       name="contact_person_house_number" 
                       class="form-control" required/>
              </div>
            </div>
            <div class="mb-2 row align-items-center">
              <div class="col-2"><label for="contact_person_street" >{{i18n('activerecord.attributes.task.contact_person_street')}} *</label></div>
              <div class="col">
                <input type="text" 
                       v-model="contact_person_street" 
                       data-address-lookup-target="street"                      
                       name="contact_person_street" 
                       class="form-control" required/>
              </div>
              <div class="col-1"><label for="contact_person_city">{{i18n('activerecord.attributes.task.contact_person_city')}} *</label></div>
              <div class="col">
                <input type="text" 
                       v-model="contact_person_city" 
                       data-address-lookup-target="city"
                       name="contact_person_city" 
                       class="form-control" required/>
              </div>
            </div>
            <div class="mb-2 row align-items-center">
              <div class="col-2"><label for="contact_person_country">{{i18n('activerecord.attributes.task.contact_person_country')}} *</label></div>
              <div class="col">
                <CountrySelect v-model="contact_person_country" data-address-lookup-target="country" :country="contact_person_country" name="contact_person_country" class="form-control" :usei18n="false" required/>
              </div>
            </div>
            <hr/>
            <div class="row mb-2">
              <div class="col-2"><label class="col-form-label" for="date">{{i18n('activerecord.attributes.task.date')}} </label></div>
              <div class="col"><input type="date" v-model="date" name="date" class="form-control" /></div>
            </div>
            <div class="row mb-2">
              <div class="col-2"><label class="col-form-label" for="start_time">{{i18n('activerecord.attributes.task.starts_at')}} </label></div>
              <div class="col"><input type="time" v-model="startTime" step="300" name="start_time" class="form-control" /></div>
              <div class="col-2"><label class="col-form-label" for="end_time">{{i18n('activerecord.attributes.task.ends_at')}}</label></div>
              <div class="col"><input type="time" v-model="endTime" step="300" name="end_time" class="form-control" /></div>
            </div>
            <hr/>
            <div class="row mb-2">
              <div class="col-2"><label class="col-form-label">{{ i18n('activerecord.attributes.task.status')}}</label></div>
              <div class="col">
                <select v-model="status" class="form-select">
                  <option>pending</option>
                  <option>customer_scheduled</option>
                  <option>pending_reschedule</option>
                  <option>confirmed</option>
                  <option>in_progress</option>
                  <option>completed</option>
                  <option>cancelled</option>
                  <option>failed</option>
                </select>
              </div>
            </div>
            <div class="row mb-2" v-if="event">
              <div class="col-2">
                <label class="col-form-label">{{ i18n('activerecord.attributes.task.user')}}</label>
              </div>
              <div>
                <select v-if="user" v-model="user" class="form-select">
                  <option v-for="usr in users" :value="{id: usr.id, title: usr.title}">{{usr.title}}</option>
                </select>
              </div>
            </div>
            <div><label class="col-form-label" for="notes">{{i18n('activerecord.attributes.task.notes')}}</label></div>
            <div class="row mb-2" v-for="note in filteredNotes">
              <div class="col">
                <div class="input-group">
                  <textarea v-model="note.description" name="notes" class="form-control"></textarea>
                  <button class="btn btn-outline-danger input-group-button" @click="deleteNote(note)">x</button>
                </div>
              </div>
            </div>
            <div class="mb-2 text-end">
              <a href="javascript:;" @click="addNote">{{i18n('note.actions.new')}}</a>
            </div>
          </form>
          
        </div>
        <div class="p-3">
          <div class="d-flex justify-content-between gap-2">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{i18n('close')}}</button>
            <button type="button" class="btn btn-primary" @click="update">{{i18n('save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">

  .input-group-button {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
  }

  .title-event-color {
    height: 8px;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
  }

  .fc-timegrid-event-harness:hover {
    z-index: 10 !important;
  }
</style>

<script>
import moment from 'moment'
import { I18n } from "i18n-js";
import en from "@locales/en.json";
import nl from "@locales/nl.json";
import { CountrySelect } from 'vue3-country-region-select';

const i18n = new I18n(en);

if(window.locale == 'nl') {
  i18n.store(nl);
  i18n.locale = 'nl';
}

export default {
  components: {
    CountrySelect
  },
  props: {
    open: {
      type: Boolean,
      default: () => false
    },
    users: {
      type: Array,
      required: false,
      default: () => []
    },
    available_task_types: {
      type: Array,
      required: true,
      default: () => []
    },
    error: {
      type: Object,
      required: false,
    },
    currentCompany: {
      type: Object,
      required: false,
    },
    currentUser: {
      type: Object,
      required: false,
    },
  },
  watch: {
    open: function (val) {
      if (val) {
        bootstrap.Modal.getOrCreateInstance(this.$refs.modal).show()
        this.user = {id: null, title: 'unassigned' }
      } else {
        bootstrap.Modal.getOrCreateInstance(this.$refs.modal).hide()
        if(!this.createError) {
          this.reset()
        }
      }
    }
  },
  data () {
    return {
      event: {},
      startTime: null,
      endTime: null,
      tasks: [],
      newTaskCount: 1,
      newTaskType: null,
      status: 'pending',
      date: null,
      user: null,
      external_order_id: null,
      contact_person_company_name: null,
      contact_person_firstname: null,
      contact_person_lastname: null,
      contact_person_phone_number: null,
      contact_person_email: null,
      contact_person_postal_code: null,
      contact_person_house_number: null,
      contact_person_street: null,
      contact_person_city: null,
      contact_person_country: 'NL',
      notes: [],
      locale: window.locale,
      task_type: null,
    }
  },
  methods: {
    createTask () {
      for(let i = 0; i < this.newTaskCount; i++) {
        this.tasks.push({task_type: JSON.parse(JSON.stringify(this.newTaskType)) })
      }
      
      this.newTaskCount = 1
      this.newTaskType = null
    },
    removeTask (task) {
      this.tasks = this.tasks.filter(t => t !== task)
    },
    setTaskType(task, event) {
      this.available_task_types.forEach(t => {
        if (t.id === parseInt(event.target.value) ) {
          task.task_type = JSON.parse(JSON.stringify(t))
        }
      })
    },
    idChange(_) {
      this.$emit('errorfix', this.event.id)
    },
    update (e) {
      if (this.$refs.createModalForm.checkValidity() && this.tasks.length > 0) {
        this.$emit('save', this.createdEvent)
      } else {
        this.$refs.createModalForm.reportValidity()
        if (this.tasks.length === 0) {
          alert('Please add at least one task')
        }
      }
    },
    close () {
      this.$emit('close')
    },
    reset () {
      this.event = {}
      this.tasks = []
      this.newTaskCount = 1
      this.newTaskType = null
      this.status = 'pending'
      this.date = null
      this.user = null
      this.external_order_id = null
      this.contact_person_company_name = null
      this.contact_person_firstname =  null
      this.contact_person_lastname =  null
      this.contact_person_phone_number = null
      this.contact_person_email = null
      this.contact_person_postal_code = null
      this.contact_person_house_number = null
      this.contact_person_street = null
      this.contact_person_city = null
      this.contact_person_country = 'NL'
      this.notes = []
      this.task_type = null
    },
    i18n (key) {
      return i18n.t(key)
    },
    addNote () {
      this.notes.push({description: ''})
    },
    deleteNote (note) {
      if(note.id) {
        note._destroy = true
      } else {
        this.notes = this.notes.filter(n => n != note)
      }
    },
    customTaskFieldsToObject (task) {
      let json = {}
      task.task_type.custom_task_fields.forEach(field => {
        json[field.api_identifier] = field.value
      })

      return json
    },
    licensePlateAttributes(ctf) {
      if(ctf.field_type == 'license_plate') {
        return {
          'data-licenseplate-lookup-target': 'licenseplate',
          'data-action': 'input->licenseplate-lookup#input',
        }
      } else {
        if(ctf.api_identifier.includes('brand') || ctf.api_identifier.includes('merk')) {
          return {
            'data-licenseplate-lookup-target': 'brand'
          }
        } else if(ctf.api_identifier.includes('model')) {
          return {
            'data-licenseplate-lookup-target': 'model'
          }
        }
      }

      return {}
    },
    getChronologicalTasks() {
      let currentTime = moment(this.date + ' ' + this.startTime);
      let interval_minutes = moment(this.date + ' ' + this.endTime).diff(currentTime, 'minutes') / this.tasks.length;

      return this.tasks.map ( (t) => { 
        let endTime = moment(currentTime).add(interval_minutes, 'minutes');

        let task = {
          identifier: t.task_type.api_identifier,
          starts_at: this.startTime ? (moment().set({'year': this.date.split('-')[0], 'month': parseInt(this.date.split('-')[1]) - 1, 'date': this.date.split('-')[2], 'hour': currentTime.hours(), 'minute': currentTime.minutes()}).toDate()) : null,
          ends_at: this.endTime ? (moment().set({'year': this.date.split('-')[0], 'month': parseInt(this.date.split('-')[1]) - 1, 'date': this.date.split('-')[2], 'hour': endTime.hours(), 'minute': endTime.minutes() }).toDate()) : null,
          status: this.status,
          user_id: this.user.id,
          contact_person_company_name: this.contact_person_company_name,
          contact_person_firstname: this.contact_person_firstname,
          contact_person_lastname: this.contact_person_lastname,
          contact_person_phone_number: this.contact_person_phone_number,
          contact_person_email: this.contact_person_email,
          contact_person_postal_code: this.contact_person_postal_code,
          contact_person_house_number: this.contact_person_house_number,
          contact_person_street: this.contact_person_street,
          contact_person_city: this.contact_person_city,
          parameters: this.customTaskFieldsToObject(t),
          notes_attributes: this.notes.map(note => {
            return {
              id: note.id,
              description: note.description,
              _destroy: note._destroy
            }
          })
        }
        
        currentTime = endTime;
        return task;
      })
    },
    quickFill () {
      // Quick fill with current user data and company address

      // Creat a random ID, so we can use it to identify the event
      // {Date + random number}
      this.external_order_id = moment().format('DDMMYY') + "R" + Math.floor(Math.random() * 1000000000)

      // Prefill the contact person data with the current user data and company support data
      this.contact_person_company_name = this.i18n('internal')
      this.contact_person_firstname = this.currentUser.firstname
      this.contact_person_lastname = this.currentUser.lastname
      this.contact_person_phone_number = this.currentCompany.support_phone_number
      this.contact_person_email = this.currentCompany.support_email
    }
  },
  computed: {
    eventType () {
      return this.event?.type
    },
    title () {
      return this.event?.title || this.i18n('tasks.actions.new')
    },
    eventColor() {
      if(this.event && this.event.borderColor) {
        return {
          backgroundColor: this.event.borderColor
        }
      }
    },
    createdEvent () {
      return {
        order: {
          id: this.external_order_id || "",
          contact_person_company_name: this.contact_person_company_name,
          contact_person_firstname: this.contact_person_firstname,
          contact_person_lastname: this.contact_person_lastname,
          contact_person_phone_number: this.contact_person_phone_number,
          contact_person_email: this.contact_person_email,
          contact_person_postal_code: this.contact_person_postal_code,
          contact_person_house_number: this.contact_person_house_number,
          contact_person_street: this.contact_person_street,
          contact_person_city: this.contact_person_city,
          contact_person_country: this.contact_person_country,
          tasks_attributes: this.getChronologicalTasks(),
        }
      }
    },
    filteredNotes () {
      if(this.event) {
        return this.notes.filter(note => !note._destroy)
      } else {
        return []
      }
    },
  },
  mounted () {
    this.$refs.modal.addEventListener('hidden.bs.modal', this.close)
    moment.locale(this.locale);
  }
}
</script>