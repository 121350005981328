// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Railsss')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Load Rails libraries in Vite.
//

import "@hotwired/turbo-rails"
import "../controllers"

import * as bootstrap from "bootstrap"
import { main } from "@popperjs/core"

window.bootstrap = bootstrap

import indexManager from '@/vue_components/scheduling_manage/index_manager.vue';
import { createApp } from "vue";



import kpiDash from '@/vue_components/dashboard/KPI_maindashboard.vue';
// let indexManagerApps = []


//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

document.documentElement.addEventListener('turbo:load', () => {
  console.log('turbo load')

  document.querySelectorAll('.kpiDashboard').forEach(element => {
    if (!element.__vue_app__) {
      let idx = createApp({
        components: {
          kpiDashboard: kpiDash
        },
        compilerOptions: {
          isCustomElement: tag => tag.startsWith('turbo')
        }
      })
      let app = idx.mount(element);
      // indexManagerApps.push(app);
    }
  })

  let indexManagerApps = []
  document.querySelectorAll('.indexManager').forEach(element => {
    if (!element.__vue_app__) {
      let idx = createApp({
        components: {
          index_manager: indexManager
        },
        compilerOptions: {
          isCustomElement: tag => tag.startsWith('turbo')
        }
      })
      let app = idx.mount(element);
      indexManagerApps.push(app);
    }
  })

});
//
// document.documentElement.addEventListener('turbo:frame-load', () => {
//   console.log('turbo frame load')
// });
//
// document.documentElement.addEventListener('turbo:render', () => {
//   console.log('turbo render')
// });
//
// document.documentElement.addEventListener('turbo:frame-render', () => {
//   console.log('turbo frame render')
// });
//
// document.documentElement.addEventListener('turbo:fetch-request-error', () => {
//   console.log('turbo:fetch-request-error')
// });
//
// document.documentElement.addEventListener('turbo:submit-end', () => {
//   console.log('turbo:submit-end')
// });
